import { ChevronDownIcon, SearchIcon } from '@chakra-ui/icons';
import {
	Box, Button, Checkbox, Flex, Icon, Input, InputGroup, InputRightElement, Link, Menu, MenuButton, MenuItem, MenuList, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Spinner, Stat, StatLabel, Table, Tbody, Td, Text, Tfoot, Th, Thead, Tooltip, Tr, useColorModeValue
} from '@chakra-ui/react';
import {
	createColumnHelper,
	flexRender,
	getCoreRowModel, getFilteredRowModel, getPaginationRowModel,
	getSortedRowModel, useReactTable
} from '@tanstack/react-table';
import Card from 'components/card/Card';
import { addDays, addMonths, addWeeks, endOfMonth, format, startOfMonth } from 'date-fns';
import Keycloak from 'keycloak-js';
import { useEffect, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { MdMarkEmailRead, MdOutlineCopyAll, MdOutlineDeleteOutline, MdOutlineEdit } from 'react-icons/md';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchWithHandling } from 'utils/common-utils';
import { formatDateDDmmYYYY, formatDateYYYYmmDD } from '../../../utils/common-utils';
import { Customer, Deadline, DeadlinesCategoryType, DeadlinesTipologyType, EmailTemplate, Notification, NotificationType, PagedCustomers, PagedDeadlines } from "../../../utils/interfaces";
import ExportExcelButton from './ExportExcelButton';
import ImportExcelButton from './ImportExcelButton';
import './pagination.css';
import { FaSync } from 'react-icons/fa'; // Esempio con Font Awesome

export default function DeadlinesTable({
	selectedRange,
	resetSelectedRange,
	keycloak,
}: {
	selectedRange: string;
	keycloak: Keycloak;
	resetSelectedRange: () => void;
}) {
	const jwtToken = localStorage.getItem('kc-token');

	const [selectedStartDate, setSelectedStartDate] = useState<string>(null);
	const [selectedEndDate, setSelectedEndDate] = useState<string>(null);

	const [typedTransactionId, setTypedTransactionId] = useState<string>('');
	const [selectedCategory, setSelectedCategory] = useState<DeadlinesCategoryType>(null);
	const [selectedTipology, setSelectedTipology] = useState<DeadlinesTipologyType>(null);

	const [loading, setLoading] = useState(true);
	const [isAddModalOpen, setIsAddModalOpen] = useState(false);
	const [categoryDetails, setDeadlinesCategory] = useState([]);
	const [deadlinesTipology, setDeadlinesTipology] = useState([]);

	// Add Modal
	const [serviceName, setServiceName] = useState('');
	const [selectedCategoryId, setSelectedCategoryId] = useState(null);
	const [selectedTipologyId, setSelectedTipologyId] = useState(null);
	const [deadlineDate, setDeadlineDate] = useState('');
	const [documentationRefs, setDocumentationRefs] = useState('');
	const [isSaveDisabled, setIsSaveDisabled] = useState(true);
	const [dateFilterErrorMessage, setDateFilterErrorMessage] = useState<string>('');

	const [notifications, setNotifications] = useState({
		oneDayBefore: false,
		oneWeekBefore: false,
		oneMonthBefore: false,
	});

	// Edit Modal
	const [editId, setEditId] = useState(null);
	const [newServiceName, setNewServiceName] = useState('');
	const [newSelectedCategoryId, setNewSelectedCategoryId] = useState(null);
	const [newSelectedTipologyId, setNewSelectedTipologyId] = useState(null);
	const [newDeadlineDate, setNewDeadlineDate] = useState('');
	const [newInsertDate, setNewInsertDate] = useState('');
	const [newEmailNotifications, setNewEmailNotifications] = useState<Notification[]>([]);

	// Copy Modal
	const [copyId, setCopyId] = useState(null);

	// Pagination
	const [pagedDeadlines, setPagedDeadlines] = useState<PagedDeadlines | null>(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [inputPageNumber, setInputPageNumber] = useState('');

	// Error
	const [backendError, setBackendError] = useState(false);

	// Customer SearchBox
	const [searchedCustomer, setSearchedCustomer] = useState('');
	const [selectedCustomer, setSelectedCustomer] = useState<Customer>();
	const [customers, setCustomers] = useState<PagedCustomers>(); // Correctly initialized as an array

	// Email Template
	const [searchedEmailTemplate, setSearchedEmailTemplate] = useState('');
	const [selectedEmailTemplate, setSelectedEmailTemplate] = useState<EmailTemplate>();
	const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>([]); // Correctly initialized as an array

	// Color
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

	const shadow = useColorModeValue(
		'14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
		'14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
	);

	// Table
	const columnHelper = createColumnHelper<Deadline>();

	const notificationTypes: Record<NotificationType, string> = {
		ONE_DAY_BEFORE: 'Giornaliera',
		ONE_WEEK_BEFORE: 'Settimanale',
		ONE_MONTH_BEFORE: 'Mensile',
	};

	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [selectedId, setSelectedId] = useState(null);
	const [lastQueryString, setLastQueryString] = useState(null);

	useEffect(() => {
		fetchDeadlines(currentPage);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage]);

	useEffect(() => {
		fetchDeadlinesCategory();
		fetchDeadlinesTipology();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (selectedRange) {
			fetchDataForStatisticsButton();
			resetSelectedRange();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedRange, resetSelectedRange]);

	useEffect(() => {
		if ((selectedStartDate && !selectedEndDate) || (!selectedStartDate && selectedEndDate)) {
			setDateFilterErrorMessage('Inizio o fine mancante');
		} else {
			setDateFilterErrorMessage('');
		}
	}, [selectedStartDate, selectedEndDate]);

	useEffect(() => {
		validateAddModalForm();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [serviceName, selectedCategoryId, selectedTipologyId, deadlineDate]);

	useEffect(() => {
		validateEditModalForm();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [newServiceName, newSelectedCategoryId, newSelectedTipologyId, newDeadlineDate]);

	useEffect(() => {
		if (backendError) {
			toast.error(
				"Errore durante la chiamata al Server, perfavore riprova.", {
				position: "bottom-right",
			});
		}
	}, [backendError]);

	const fetchDataForStatisticsButton = async () => {
		const today = new Date();
		let startDate: Date;
		let endDate: Date;
		let queryParams = [];

		if (selectedRange === 'today') {
			startDate = today;
			endDate = today;
		} else if (selectedRange === 'week') {
			startDate = today;
			endDate = new Date(today);
			endDate.setDate(today.getDate() + 7);
		} else if (selectedRange === 'month') {
			const firstDayOfMonth = startOfMonth(today); // Primo giorno del mese
			const lastDayOfMonth = endOfMonth(today);    // Ultimo giorno del mese		
			startDate = firstDayOfMonth;
			endDate = lastDayOfMonth;
		}

		setSelectedStartDate(format(startDate, 'yyyy-MM-dd'));
		setSelectedEndDate(format(endDate, 'yyyy-MM-dd'));

		try {
			setLoading(true);
			let uri = `${process.env.REACT_APP_BACKEND_API_PREFIX}/api/v1/deadlines`;

			queryParams.push(`startDate=${format(startDate, 'yyyy-MM-dd')}`);
			queryParams.push(`endDate=${format(endDate, 'yyyy-MM-dd')}`);

			let queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
			setLastQueryString(queryString);

			const jsonData = await fetchWithHandling(
				uri + queryString,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${jwtToken}`,
					},
				},
				() => {
					toast.error(
						"Sessione scaduta. Effettua nuovamente l'accesso.", {
						position: "bottom-right",
						onClose: () => {
							keycloak.logout();
							localStorage.removeItem("kc-token");
						}
					}
					);
				},
			);

			if (jsonData) {
				setPagedDeadlines(jsonData);
			}

		} catch (error) {
			setBackendError(true);
		} finally {
			setLoading(false);
		}
	};

	const validateAddModalForm = () => {
		if (serviceName && selectedCategoryId && selectedTipologyId && deadlineDate) {
			setIsSaveDisabled(false);
		} else {
			setIsSaveDisabled(true);
		}
	};

	const validateEditModalForm = () => {
		if (newServiceName && newSelectedCategoryId && newSelectedTipologyId && newDeadlineDate) {
			setIsSaveDisabled(false);
		} else {
			setIsSaveDisabled(true);
		}
	};

	const renderNotificationIcons = (notifications: Notification[]) => {
		return (Object.keys(notificationTypes) as NotificationType[])
			.filter((type) => notifications?.some((notification) => notification.notificationType === type))
			.map((type) => {
				const notification = notifications.find((notification) => notification.notificationType === type);
				const isSent = notification?.sent;
				const sentDate = notification?.sentDate;
				const errorOccured = notification?.errorOccured;
				const errorMessage = notification?.errorMessage;

				let iconColor;
				let tooltipLabel;

				if (errorOccured === true && errorMessage) {
					iconColor = 'var(--chakra-colors-red-500)';
					tooltipLabel = `${notificationTypes[type]}, data di invio: ${new Date(sentDate).toLocaleString()}, errore: ${errorMessage}`;
				} else if (isSent === true) {
					iconColor = 'var(--chakra-colors-green-500)';
					tooltipLabel = `${notificationTypes[type]}, data di invio: ${new Date(sentDate).toLocaleString()}`;
				} else {
					iconColor = 'gray.300';
					tooltipLabel = notificationTypes[type];
				}

				return (
					<Tooltip key={type} label={tooltipLabel} hasArrow>
						<span tabIndex={0}>
							<Icon as={MdMarkEmailRead} w={6} h={6} color={iconColor} mx="2" />
						</span>
					</Tooltip>
				);
			});
	};

	const handleCheckboxChange = (event: any) => {
		const { name, checked } = event.target;
		setNotifications((prev) => ({
			...prev,
			[name]: checked,
		}));
	};

	const handlePageChange = (newPage: number) => {
		if (newPage >= 0 && newPage <= pagedDeadlines.totalPages) {
			setCurrentPage(newPage);
		}
	};

	const buildURI = (
		pageNumber: number,
		typedTransactionId: string = ''
	) => {
		let baseUrl = `${process.env.REACT_APP_BACKEND_API_PREFIX}/api/v1/deadlines`;
		let queryParams = [];

		if (pageNumber) {
			queryParams.push(`pageNumber=${pageNumber - 1}`);
		}

		if (selectedStartDate) {
			queryParams.push(`startDate=${selectedStartDate}`);
		}

		if (selectedEndDate) {
			queryParams.push(`endDate=${selectedEndDate}`);
		}

		if (typedTransactionId !== '') {
			queryParams.push(`serviceName=${typedTransactionId}`);
		}

		if (selectedCategory) {
			queryParams.push(`category=${selectedCategory.id}`);
		}

		if (selectedTipology) {
			queryParams.push(`tipology=${selectedTipology.id}`);
		}

		let queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
		const uri = baseUrl + queryString;

		setLastQueryString(queryString);
		return uri;
	};

	const fetchDeadlines = async (pageNumber?: number, typedTransactionId?: string) => {
		setLoading(true);
		try {
			const uri = buildURI(pageNumber, typedTransactionId);
			const jsonData = await fetchWithHandling(
				uri,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${jwtToken}`,
					},
				},
				() => {
					toast.error(
						"Sessione scaduta. Effettua nuovamente l'accesso.", {
						position: "bottom-right",
						onClose: () => {
							keycloak.logout();
							localStorage.removeItem("kc-token");
						}
					}
					);
				}
			);

			if (jsonData) {
				setPagedDeadlines(jsonData);
			}

		} catch (error) {
			setBackendError(true); // Imposto un errore di backend se qualcosa va storto
		} finally {
			setLoading(false); // Disattivo lo stato di caricamento
		}
	};

	const resetFilters = async () => {
		setTypedTransactionId('');
		setSelectedCategory(null);
		setSelectedTipology(null);
		setSelectedStartDate(null);
		setSelectedEndDate(null);

		try {
			setLoading(true);
			const uri = `${process.env.REACT_APP_BACKEND_API_PREFIX}/api/v1/deadlines`;
			const jsonData = await fetchWithHandling(
				uri,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${jwtToken}`,
					},
				},
				() => {
					toast.error(
						"Sessione scaduta. Effettua nuovamente l'accesso.", {
						position: "bottom-right",
						onClose: () => {
							keycloak.logout();
							localStorage.removeItem("kc-token");
						}
					}
					);
				}
			);

			if (jsonData) {
				setPagedDeadlines(jsonData);
			} else {
				console.error("Struttura della risposta non coerente: :", jsonData);
			}

		} catch (error) {
			setBackendError(true);
		} finally {
			setLoading(false);
		}

		setLastQueryString(null);

	};

	const handleApplyFilters = () => {
		if (dateFilterErrorMessage) {
			return;
		}
		fetchDeadlines(currentPage, typedTransactionId);
	};

	const columns = [
		columnHelper.accessor('serviceName', {
			id: 'serviceName',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					paddingInlineStart='var(--chakra-space-6)'
					marginRight='auto'
					fontSize={{ sm: '10px', lg: '14px' }}
					textTransform="none"
					color='gray.400'>
					Nome Servizio
				</Text>
			),
			cell: (info: any) => (
				<Flex align='center'>
					<Text
						color={textColor}
						fontSize='xs'
						fontWeight='700'
					>
						{info.getValue()}
					</Text>
				</Flex>
			)
		}),
		columnHelper.accessor('category', {
			id: 'category',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					marginLeft='auto'
					marginRight='auto'
					fontSize={{ sm: '10px', lg: '14px' }}
					textTransform="none"
					color='gray.400'
					textAlign="center"
				>
					Categoria
				</Text>
			),
			cell: (info: any) => (
				<Flex align='center'>
					{info.getValue() != null && info.getValue().name != null && info.getValue().name !== "" ? (
						<Text textAlign="center" color={textColor} fontSize='xs' fontWeight='700' marginLeft='auto' marginRight='auto'>
							{info.getValue().name}
						</Text>
					) : (
						<Text textAlign="center" color={textColor} fontSize='xs' fontWeight='700' marginLeft='auto' marginRight='auto'>
							-
						</Text>
					)}
				</Flex>
			)
		}),
		columnHelper.accessor('tipology', {
			id: 'tipology',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					marginLeft='auto'
					marginRight='auto'
					fontSize={{ sm: '10px', lg: '14px' }}
					textTransform="none"
					color='gray.400'
					textAlign="center"
				>
					Tipologia
				</Text>
			),
			cell: (info: any) => (
				<Flex align='center'>
					{info.getValue() != null && info.getValue().name != null && info.getValue().name !== "" ? (
						<Text textAlign="center" color={textColor} fontSize='xs' fontWeight='700' marginLeft='auto' marginRight='auto'>
							{info.getValue().name}
						</Text>
					) : (
						<Text textAlign="center" color={textColor} fontSize='xs' fontWeight='700' marginLeft='auto' marginRight='auto'>
							-
						</Text>
					)}
				</Flex>
			)
		}),
		columnHelper.accessor('insertDate', {
			id: 'insertDate',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					marginLeft='auto'
					marginRight='auto'
					fontSize={{ sm: '10px', lg: '14px' }}
					textTransform="none"
					color='gray.400'
					textAlign="center"
				>
					Data Inserimento
				</Text>
			),
			cell: (info) => (
				<Flex align='center'>
					{info.getValue() != null ? (
						<Text textAlign="center" color={textColor} fontSize='xs' fontWeight='700' marginLeft='auto' marginRight='auto'>
							{formatDateDDmmYYYY(info.getValue())}
						</Text>
					) : (
						<Text textAlign="center" color={textColor} fontSize='xs' fontWeight='700' marginLeft='auto' marginRight='auto'>
							-
						</Text>
					)}
				</Flex>
			)
		}),
		columnHelper.accessor('deadlineDate', {
			id: 'deadlineDate',
			header: () => (
				<Text
					display="flex"
					alignItems="center"
					justifyContent="space-between"
					margin='auto'
					fontSize={{ sm: '10px', lg: '14px' }}
					textTransform="none"
					color='gray.400'
					textAlign="center"
				>
					<span>Data Scadenza</span>
				</Text>
			),
			cell: (info) => (
				<Flex align='center'>
					{info.getValue() != null ? (
						<Text textAlign="center" color={textColor} fontSize='xs' fontWeight='700' marginLeft='auto' marginRight='auto'>
							{formatDateDDmmYYYY(info.getValue())}
						</Text>
					) : (
						<Text textAlign="center" color={textColor} fontSize='xs' fontWeight='700' marginLeft='auto' marginRight='auto'>
							-
						</Text>
					)}
				</Flex>
			)
		}),
		columnHelper.accessor('documentationRefs', {
			id: 'documentationRefs',
			header: () => (
				<Text
					marginLeft='auto'
					marginRight='auto'
					justifyContent='center'
					align='center'
					fontSize={{ sm: '10px', lg: '14px' }}
					textTransform="none"
					color='gray.400'
					textAlign="center"
				>
					Link Doc.
				</Text>
			),
			cell: (info: any) => (
				<Text textAlign="center" color={textColor} fontSize='xs' fontWeight='700' marginLeft='auto' marginRight='auto'>
					{info.row.original.documentationRefs ? (
						<Link
							href={info.row.original.documentationRefs}
							isExternal
							color="blue.500"
							textDecoration="underline"
							_hover={{ color: "blue.700" }}
							fontSize='xs'
							fontWeight='700'
							marginLeft='auto'
							marginRight='auto'
							textAlign="center"
						>
							Link
						</Link>
					) : (
						"-"
					)}
				</Text>
			)
		}),
		columnHelper.accessor('emailNotifications', {
			id: 'emailNotifications',
			header: () => (
				<Text
					marginLeft='auto'
					marginRight='auto'
					justifyContent='center'
					align='center'
					fontSize={{ sm: '10px', lg: '14px' }}
					textTransform="none"
					color='gray.400'
				>
					Notifiche Email
				</Text>
			),
			cell: (info: any) => (
				<Flex justifyContent='center' alignItems='center'>
					{renderNotificationIcons(info.row.original.emailNotifications)}
				</Flex>
			)
		}),
		{
			id: 'operation',
			header: () => (
				<Text
					marginLeft='auto'
					marginRight='auto'
					justifyContent='center'
					align='center'
					fontSize={{ sm: '10px', lg: '14px' }}
					textTransform="none"
					color='gray.400'
				>
					Operazioni
				</Text>
			),
			cell: (info: any) => (
				<Flex justifyContent='center'>
					<Tooltip label="Copia" fontSize="md">
						<Button
							onClick={() => openCopyModal(info.row.original)}
							_hover={{ bg: 'gray.200' }}
							_active={{ bg: 'gray.100' }}
							_focus={{ boxShadow: 'none' }}
							color="white"
							fontSize='xs'
							mx="1"
							style={{
								height: '30px',
								padding: '0px 12px',
								borderRadius: '6px',
								border: 'none',
								cursor: 'pointer'
							}}
						>
							<Icon as={MdOutlineCopyAll} w={4} h={4} color="black" />
						</Button>
					</Tooltip>
					<Tooltip label="Modifica" fontSize="md">
						<Button
							onClick={() => openEditModal(info.row.original)}
							_hover={{ bg: 'gray.200' }}
							_active={{ bg: 'gray.100' }}
							_focus={{ boxShadow: 'none' }}
							color="white"
							style={{
								height: '30px',
								padding: '0px 12px',
								borderRadius: '6px',
								border: 'none',
								cursor: 'pointer'
							}}
							fontSize='xs'
							mx="1"
						>
							<Icon as={MdOutlineEdit} w={4} h={4} color="black" />
						</Button>
					</Tooltip>
					<Tooltip label="Elimina" fontSize="md">
						<Button
							onClick={() => openDeleteModal(info.row.original.id)}
							_hover={{ bg: 'gray.200' }}
							_active={{ bg: 'gray.100' }}
							_focus={{ boxShadow: 'none' }}
							color="white"
							style={{
								height: '30px',
								padding: '0px 12px',
								borderRadius: '6px',
								border: 'none',
								cursor: 'pointer'
							}}
							fontSize='xs'
							mx="1"
							isLoading={loading}
						>
							<Icon as={MdOutlineDeleteOutline} w={4} h={4} color="black" />
						</Button>
					</Tooltip>
				</Flex>
			)
		}
	];

	const table = useReactTable({
		columns,
		data: pagedDeadlines?.content,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		debugTable: false, // Disabilita i log di debug della tabella
		debugHeaders: false, // Disabilita i log di debug degli header
		debugColumns: false  // Disabilita i log di debug delle colonne
	});

	const handleStartDateChange = (event: any) => {
		const startDate = event.target.value || null;
		setSelectedStartDate(startDate);
	};

	const handleEndDateChange = (event: any) => {
		const endDate = event.target.value || null;
		setSelectedEndDate(endDate);
	};

	const fetchDeadlinesCategory = async () => {
		try {
			const uri = `${process.env.REACT_APP_BACKEND_API_PREFIX}/api/v1/deadlines_category`;
			const jsonData = await fetchWithHandling(
				uri,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${jwtToken}`,
					},
				},
				() => {
					toast.error(
						"Sessione scaduta. Effettua nuovamente l'accesso.", {
						position: "bottom-right",
						onClose: () => {
							keycloak.logout();
							localStorage.removeItem("kc-token");
						}
					}
					);
				}
			);

			if (jsonData) {
				setDeadlinesCategory(jsonData);
			}
		} catch (error) {
			setBackendError(true);
		}
	};

	const fetchDeadlinesTipology = async () => {
		try {
			const uri = `${process.env.REACT_APP_BACKEND_API_PREFIX}/api/v1/deadlines_tipology`;
			const jsonData = await fetchWithHandling(
				uri,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${jwtToken}`,
					},
				},
				() => {
					toast.error(
						"Sessione scaduta. Effettua nuovamente l'accesso.", {
						position: "bottom-right",
						onClose: () => {
							keycloak.logout();
							localStorage.removeItem("kc-token");
						}
					}
					);
				}
			);

			if (jsonData) {
				setDeadlinesTipology(jsonData);
			}
		} catch (error) {
			setBackendError(true);
		}
	};

	const openAddModal = () => {
		setIsAddModalOpen(true);
	};

	const openEditModal = (deadline: Deadline) => {
		setEditId(deadline.id);
		setNewServiceName(deadline.serviceName);
		setNewSelectedCategoryId(deadline.category.id);
		setNewSelectedTipologyId(deadline.tipology.id);
		setNewDeadlineDate(deadline.deadlineDate);
		setNewInsertDate(deadline.insertDate);
		setDocumentationRefs(deadline.documentationRefs);
		setNewEmailNotifications(deadline.emailNotifications);
		setNotifications(mapNotifications(deadline.emailNotifications));
		setSelectedCustomer(deadline.customer);
		setSelectedEmailTemplate(deadline.emailTemplate)
	};

	const openCopyModal = (deadline: Deadline) => {
		setCopyId(deadline.id);
		setNewServiceName(deadline.serviceName);
		setNewSelectedCategoryId(deadline.category.id);
		setNewSelectedTipologyId(deadline.tipology.id);
		setNewDeadlineDate(deadline.deadlineDate);
		setNewInsertDate(deadline.insertDate);
		setDocumentationRefs(deadline.documentationRefs);
		setNewEmailNotifications(deadline.emailNotifications);
		setNotifications(mapNotifications(deadline.emailNotifications));
		setSelectedCustomer(deadline.customer);
		setSelectedEmailTemplate(deadline.emailTemplate);
	};

	const closeAddModal = () => {
		setIsAddModalOpen(false);
		setServiceName('');
		setSelectedCategoryId(null);
		setSelectedTipologyId(null);
		setDeadlineDate('');
		setDocumentationRefs("");
		setSelectedCustomer(null);
		setSelectedEmailTemplate(null);
		setNotifications({
			oneDayBefore: false,
			oneWeekBefore: false,
			oneMonthBefore: false,
		});
		setEmailTemplates([]);
		setCustomers(null);
		setSearchedCustomer("");
		setSearchedEmailTemplate("");
	};

	const closeEditModal = () => {
		setEditId(null);
		setNewServiceName("");
		setNewSelectedCategoryId(null);
		setNewSelectedTipologyId(null);
		setNewDeadlineDate("");
		setNewInsertDate("");
		setDocumentationRefs("");
		setNewEmailNotifications([]);
		setNotifications({
			oneDayBefore: false,
			oneWeekBefore: false,
			oneMonthBefore: false,
		});
		setSelectedCustomer(null);
		setSelectedEmailTemplate(null);
		setEmailTemplates([]);
		setCustomers(null);
		setSearchedCustomer("");
		setSearchedEmailTemplate("");
	};

	const closeCopyModal = () => {
		setCopyId(null);
		setNewServiceName("");
		setNewSelectedCategoryId(null);
		setNewSelectedTipologyId(null);
		setNewDeadlineDate("");
		setNewInsertDate("");
		setDocumentationRefs("");
		setNewEmailNotifications([]);
		setNotifications({
			oneDayBefore: false,
			oneWeekBefore: false,
			oneMonthBefore: false,
		});
		setSelectedCustomer(null);
		setSelectedEmailTemplate(null);
		setEmailTemplates([]);
		setCustomers(null);
		setSearchedCustomer("");
		setSearchedEmailTemplate("");
	};

	const copyDeadline = async () => {
		try {
			setLoading(true);

			const emailNotifications: any = [];
			if (notifications.oneDayBefore) {
				emailNotifications.push({
					notificationType: 'ONE_DAY_BEFORE',
					notificationDate: calculateNotificationDate(newDeadlineDate, 'ONE_DAY_BEFORE')
				});
			}

			if (notifications.oneWeekBefore) {
				emailNotifications.push({
					notificationType: 'ONE_WEEK_BEFORE',
					notificationDate: calculateNotificationDate(newDeadlineDate, 'ONE_WEEK_BEFORE')
				});
			}

			if (notifications.oneMonthBefore) {
				emailNotifications.push({
					notificationType: 'ONE_MONTH_BEFORE',
					notificationDate: calculateNotificationDate(newDeadlineDate, 'ONE_MONTH_BEFORE')
				});
			}

			const requestBody = {
				serviceName: newServiceName,
				deadlineDate: newDeadlineDate,
				insertDate: newInsertDate,
				documentationRefs,
				category: { id: newSelectedCategoryId },
				tipology: { id: newSelectedTipologyId },
				emailNotifications,
				...(selectedCustomer && { customer: { id: selectedCustomer.id } }),
				...(selectedEmailTemplate && { emailTemplate: { id: selectedEmailTemplate.id } })
			};

			setNotifications({
				oneDayBefore: false,
				oneWeekBefore: false,
				oneMonthBefore: false,
			});

			const uri = `${process.env.REACT_APP_BACKEND_API_PREFIX}/api/v1/deadlines`;

			const jsonData = await fetchWithHandling(
				uri,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${jwtToken}`,
					},
					body: JSON.stringify(requestBody)
				},
				() => {
					toast.error(
						"Sessione scaduta. Effettua nuovamente l'accesso.", {
						position: "bottom-right",
						onClose: () => {
							keycloak.logout();
							localStorage.removeItem("kc-token");
						}
					}
					);
				}
			);

			if (jsonData) {
				fetchDeadlines();
				closeEditModal();
			}
			setLoading(false);
		} catch (error) {
			setBackendError(true);
			setLoading(false);
		}
		closeCopyModal();
	};

	const addDeadline = async () => {
		try {
			setLoading(true);
			const uri = `${process.env.REACT_APP_BACKEND_API_PREFIX}/api/v1/deadlines`;

			const emailNotifications = [];
			if (notifications.oneDayBefore) {
				emailNotifications.push({
					notificationType: 'ONE_DAY_BEFORE',
					notificationDate: calculateNotificationDate(deadlineDate, 'ONE_DAY_BEFORE')
				});
			}
			if (notifications.oneWeekBefore) {
				emailNotifications.push({
					notificationType: 'ONE_WEEK_BEFORE',
					notificationDate: calculateNotificationDate(deadlineDate, 'ONE_WEEK_BEFORE')
				});
			}
			if (notifications.oneMonthBefore) {
				emailNotifications.push({
					notificationType: 'ONE_MONTH_BEFORE',
					notificationDate: calculateNotificationDate(deadlineDate, 'ONE_MONTH_BEFORE')
				});
			}

			const requestBody = {
				serviceName,
				insertDate: new Date().toISOString(),
				deadlineDate,
				documentationRefs,
				category: { id: selectedCategoryId },
				tipology: { id: selectedTipologyId },
				emailNotifications,
				...(selectedCustomer && { customer: { id: selectedCustomer.id } }),
				...(selectedEmailTemplate && { emailTemplate: { id: selectedEmailTemplate.id } })
			};

			setServiceName('');
			setSelectedCategoryId(null);
			setSelectedTipologyId(null);
			setDeadlineDate('');
			setDocumentationRefs("");
			setSelectedCustomer(null);
			setSelectedEmailTemplate(null);
			setNotifications({
				oneDayBefore: false,
				oneWeekBefore: false,
				oneMonthBefore: false,
			});

			const jsonData = await fetchWithHandling(
				uri,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${jwtToken}`,
					},
					body: JSON.stringify(requestBody)
				},
				() => {
					toast.error(
						"Sessione scaduta. Effettua nuovamente l'accesso.", {
						position: "bottom-right",
						onClose: () => {
							keycloak.logout();
							localStorage.removeItem("kc-token");
						}
					}
					);
				},
			);

			if (jsonData) {
				toast.success(
					"Scadenza aggiunta con successo", {
					position: "bottom-right",
				}
				);
				fetchDeadlines();
				closeAddModal();
			} else {
				toast.error(
					"Errore durante l'aggiunta della scadenza", {
					position: "bottom-right",
				});
			}

			setLoading(false);
		} catch (error) {
			setBackendError(true);
			setLoading(false);
		}
	};

	const deleteDeadline = async (id: number) => {
		try {
			setLoading(true);

			const uri = `${process.env.REACT_APP_BACKEND_API_PREFIX}/api/v1/deadlines/${id}`;
			const response = await fetchWithHandling(
				uri,
				{
					method: 'DELETE',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${jwtToken}`,
					},
				},
				() => {
					toast.error(
						"Sessione scaduta. Effettua nuovamente l'accesso.", {
						position: "bottom-right",
						onClose: () => {
							keycloak.logout();
							localStorage.removeItem("kc-token");
						}
					}
					);
				}
			);

			fetchDeadlines();

			if (response.ok) {
				toast.success(
					"Scadenza eliminata con successo", {
					position: "bottom-right",
				}
				);
			} else {
				console.error("Errore dal server:", response);
				toast.error(
					"Errore durante l'eliminazione della scadenza", {
					position: "bottom-right",
				});
			}

		} catch (error) {
			setBackendError(true);
		} finally {
			setLoading(false);
		}
	};

	const editDeadline = async () => {
		try {
			setLoading(true);

			const notificationTypes: { type: NotificationType, value: boolean }[] = [
				{ type: 'ONE_DAY_BEFORE' as NotificationType, value: notifications.oneDayBefore },
				{ type: 'ONE_WEEK_BEFORE' as NotificationType, value: notifications.oneWeekBefore },
				{ type: 'ONE_MONTH_BEFORE' as NotificationType, value: notifications.oneMonthBefore },
			];

			notificationTypes.forEach(notification => {
				/* A non-negative integer: This represents the index of the element in the newEmailNotifications array
				 that satisfies the condition n.notificationType === notification.type.

				 -1: This value indicates that no element in the newEmailNotifications array satisfies the condition*/
				const existingNotificationIndex = newEmailNotifications.findIndex(
					n => n.notificationType === notification.type
				);

				if (notification.value) {
					const newNotificationDate = calculateNotificationDate(newDeadlineDate, notification.type);
					const newNotification = {
						notificationType: notification.type,
						notificationDate: newNotificationDate
					};

					if (existingNotificationIndex === -1) {
						newEmailNotifications.push(newNotification);
					} else {
						const existingNotification = newEmailNotifications[existingNotificationIndex];
						const newNotificationDateFormatted = formatDateYYYYmmDD(newNotificationDate);
						if (existingNotification.notificationDate !== newNotificationDateFormatted) {
							newEmailNotifications[existingNotificationIndex] = newNotification;
						}
					}
				} else {
					/* If notification.value is false, check existing notification index and 
					   if exist remove the existing notification from the array at the existingNotificationIndex.*/
					if (existingNotificationIndex !== -1) {
						newEmailNotifications.splice(existingNotificationIndex, 1);
					}
				}
			});

			const requestBody = {
				id: editId,
				serviceName: newServiceName,
				deadlineDate: newDeadlineDate,
				insertDate: newInsertDate,
				documentationRefs,
				category: { id: newSelectedCategoryId },
				tipology: { id: newSelectedTipologyId },
				emailNotifications: newEmailNotifications,
				...(selectedCustomer && { customer: { id: selectedCustomer.id } }),
				...(selectedEmailTemplate && { emailTemplate: { id: selectedEmailTemplate.id } })
			};

			setNotifications({
				oneDayBefore: false,
				oneWeekBefore: false,
				oneMonthBefore: false,
			});

			const uri = `${process.env.REACT_APP_BACKEND_API_PREFIX}/api/v1/deadlines`;
			const jsonData = await fetchWithHandling(
				uri,
				{
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${jwtToken}`,
					},
					body: JSON.stringify(requestBody)
				},
				() => {
					toast.error(
						"Sessione scaduta. Effettua nuovamente l'accesso.", {
						position: "bottom-right",
						onClose: () => {
							keycloak.logout();
							localStorage.removeItem("kc-token");
						}
					}
					);
				}
			);

			if (jsonData) {
				fetchDeadlines();
				closeAddModal();
				toast.success(
					"Scadenza modificata con successo", {
					position: "bottom-right",
				}
				);
				fetchDeadlines();
				closeEditModal();
			} else {
				toast.error(
					"Errore durante la modifica della scadenza", {
					position: "bottom-right",
				});
			}

			setLoading(false);
		} catch (error) {
			setBackendError(true);
			setLoading(false);
		}
	};

	const calculateNotificationDate = (deadlineDate: string, notificationType: string): string | null => {
		const deadline = new Date(deadlineDate);

		switch (notificationType) {
			case 'ONE_DAY_BEFORE':
				return addDays(deadline, -1).toISOString();
			case 'ONE_WEEK_BEFORE':
				return addWeeks(deadline, -1).toISOString();
			case 'ONE_MONTH_BEFORE':
				return addMonths(deadline, -1).toISOString();
			default:
				return null;
		}
	};

	const mapNotifications = (emailNotifications: Notification[]) => {
		return {
			oneDayBefore: emailNotifications.some(n => n.notificationType === 'ONE_DAY_BEFORE'),
			oneWeekBefore: emailNotifications.some(n => n.notificationType === 'ONE_WEEK_BEFORE'),
			oneMonthBefore: emailNotifications.some(n => n.notificationType === 'ONE_MONTH_BEFORE'),
		};
	};

	const openDeleteModal = (id: number) => {
		setSelectedId(id);
		setIsDeleteModalOpen(true);
	};

	const closeDeleteModal = () => {
		setIsDeleteModalOpen(false);
		setSelectedId(null);
	};

	const confirmDelete = () => {
		closeDeleteModal();
		deleteDeadline(selectedId);
	};

	const handleExcelImportSuccess = async () => {
		fetchDeadlines(currentPage);
	};

	const fetchCustomers = async (fullName?: string) => {

		try {

			let baseUrl = `${process.env.REACT_APP_BACKEND_API_PREFIX}/api/v1/customers/paginated`;
			let queryParams = [];

			if (fullName) {
				queryParams.push(`fullName=${fullName}`);
			}

			let queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
			const uri = baseUrl + queryString;

			const jsonData = await fetchWithHandling(
				uri,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${jwtToken}`,
					},
				},
				() => {
					toast.error(
						"Sessione scaduta. Effettua nuovamente l'accesso.", {
						position: "bottom-right",
						onClose: () => {
							keycloak.logout();
							localStorage.removeItem("kc-token");
						}
					}
					);
				}
			);

			if (jsonData) {
				setCustomers(jsonData);
			}

		} catch (error) {
			setBackendError(true); // Imposto un errore di backend se qualcosa va storto
		}
	};

	const fetchEmailTemplates = async () => {
		try {
			const uri = `${process.env.REACT_APP_BACKEND_API_PREFIX}/api/v1/email-templates`;
			const jsonData = await fetchWithHandling(
				uri,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${jwtToken}`,
					},
				},
				() => {
					toast.error(
						"Sessione scaduta. Effettua nuovamente l'accesso.", {
						position: "bottom-right",
						onClose: () => {
							keycloak.logout();
							localStorage.removeItem("kc-token");
						}
					}
					);
				}
			);

			if (jsonData) {
				setEmailTemplates(jsonData);
			}

		} catch (error) {
			setBackendError(true); // Imposto un errore di backend se qualcosa va storto
		}
	};


	const handleCustomerSelect = (option: Customer) => {
		setSelectedCustomer(option);
	};

	const handleEmailTemplateSelect = (option: EmailTemplate) => {
		setSelectedEmailTemplate(option);
	};

	const filteredEmailTemplate = emailTemplates?.filter((template: EmailTemplate) =>
		template.name.toLowerCase().includes(searchedEmailTemplate.toLowerCase())
	);

	const handleFetchDeadlines = () => {
		fetchDeadlines();
	};

	const truncateName = (name: string, maxLength: number = 20): string => {
		if (name.length <= maxLength) return name;
		const start = name.slice(0, maxLength - 3);
		return `${start}...`;
	  };

	return (
		<Card background='none' p="0px">
			<ToastContainer />
			<SimpleGrid
				px="10px"
				mx="10px"
				width={{ base: '100%', md: '96%' }}
				marginLeft={{ base: '0px', md: '0px' }}
				py='10px'
				backgroundColor='white'
				borderRadius='20px'
				boxShadow={shadow}
				columns={{ base: 2, md: 7, lg: 7, '2xl': 7 }} // Adjust number of columns based on screen size
				mb='20px'
				alignItems="center" // Center items vertically
				justifyContent="center" // Center items horizontally	
			>
				<Card py='15px' gridColumn={{ base: 'span 2', md: 'span 1' }}>
					<Flex my='auto' h='100%' align={{ base: 'center', xl: 'start' }} justify={{ base: 'center', xl: 'center' }}>
						<Stat my='auto'>
							<StatLabel lineHeight='100%' fontWeight="bold" fontSize={{ base: 'sm' }}>
								Nome Servizio
							</StatLabel>
							<Flex ms='auto' mt='3%'>
								<Input
									type="text"
									borderRadius='6px'
									size="sm"
									placeholder="Cerca servizio..."
									value={typedTransactionId}
									onChange={(e) => setTypedTransactionId(e.target.value)}
									focusBorderColor="gray.500"
									_hover={{ borderColor: 'gray.500' }}
								/>
							</Flex>
						</Stat>
					</Flex>
				</Card>

				{/* Tipologia and Categoria side by side on mobile */}
				<Card py='15px' gridColumn={{ base: 'span 1', md: 'span 1' }}>
					<Flex my='auto' h='100%' align={{ base: 'center', xl: 'start' }} justify={{ base: 'center', xl: 'center' }}>
						<Stat my='auto'>
							<StatLabel lineHeight='100%' fontWeight="bold" fontSize={{ base: 'sm' }}>
								Categoria
							</StatLabel>
							<Menu>
								<MenuButton
									as={Button}
									rightIcon={<IoIosArrowDown />}
									mt='3%'
									fontSize='sm'
									variant='subtle'
									width='100%'
									size="xs"
									color="var(--chakra-colors-secondaryGray-600)"
									textAlign="left"
									fontWeight="normal"
									border="1px solid"
									borderColor="gray.300"
									borderRadius="0.375rem"
								>
									{selectedCategory?.name ? (selectedCategory.name.length > 9 ? selectedCategory.name.substring(0, 9) + '...' : selectedCategory.name) : "Tutte"}
								</MenuButton>
								<MenuList textAlign="left" fontSize='sm' maxHeight="220px" overflowY="auto">
									<MenuItem onClick={() => setSelectedCategory(null)}>Tutte</MenuItem>
									{(categoryDetails || []).map((source) => (
										<MenuItem _hover={{ bg: 'gray.200' }} key={source.id} onClick={() => setSelectedCategory(source)}>
											{source.name}
										</MenuItem>
									))}
								</MenuList>
							</Menu>
						</Stat>
					</Flex>
				</Card>

				<Card py='15px' gridColumn={{ base: 'span 1', md: 'span 1' }}>
					<Flex my='auto' h='100%' align={{ base: 'center', xl: 'start' }} justify={{ base: 'center', xl: 'center' }}>
						<Stat my='auto'>
							<StatLabel lineHeight='100%' fontWeight="bold" fontSize={{ base: 'sm' }}>
								Tipologia
							</StatLabel>
							<Menu>
								<MenuButton
									as={Button}
									rightIcon={<IoIosArrowDown />}
									mt='3%'
									fontSize='sm'
									variant='subtle'
									width='100%'
									size="xs"
									color="var(--chakra-colors-secondaryGray-600)"
									textAlign="left"
									fontWeight="normal"
									border="1px solid"
									borderColor="gray.300"
									borderRadius="0.375rem"
								>
									{selectedTipology?.name ? (selectedTipology.name.length > 9 ? selectedTipology.name.substring(0, 9) + '...' : selectedTipology.name) : "Tutte"}
								</MenuButton>
								<MenuList fontSize='sm' textAlign="left" maxHeight="220px" overflowY="auto">
									<MenuItem onClick={() => setSelectedTipology(null)}>Tutte</MenuItem>
									{(deadlinesTipology || []).map((consumer) => (
										<MenuItem _hover={{ bg: 'gray.200' }} key={consumer.id} onClick={() => setSelectedTipology(consumer)}>
											{consumer.name}
										</MenuItem>
									))}
								</MenuList>
							</Menu>
						</Stat>
					</Flex>
				</Card>

				<Card py='15px' gridColumn={{ base: 'span 1', md: 'span 1' }}>
					<Flex my='auto' h='100%' align={{ base: 'center', xl: 'start' }} justify={{ base: 'center', xl: 'center' }}>
						<Stat my='auto' position="relative">
							<StatLabel lineHeight='100%' fontWeight="bold" fontSize={{ base: 'sm' }}>
								Inizio Scadenza
							</StatLabel>
							<Input
								mt='2%'
								value={selectedStartDate || ''}
								onChange={handleStartDateChange}
								size="sm"
								borderRadius='6px'
								type="date"
								focusBorderColor="gray.500"
								color={"var(--chakra-colors-secondaryGray-600)"}
								_hover={{ borderColor: 'gray.500' }}
							/>
							{dateFilterErrorMessage && !selectedStartDate && (
								<Text color="red.500" fontSize="sm" position="absolute" top="100%" mt="2px">
									{dateFilterErrorMessage}
								</Text>
							)}
						</Stat>
					</Flex>
				</Card>

				<Card py='15px' gridColumn={{ base: 'span 1', md: 'span 1' }}>
					<Flex my='auto' h='100%' align={{ base: 'center', xl: 'start' }} justify={{ base: 'center', xl: 'center' }}>
						<Stat my='auto' position="relative">
							<StatLabel lineHeight='100%' fontWeight="bold" fontSize={{ base: 'sm' }}>
								Fine Scadenza
							</StatLabel>
							<Input
								mt='2%'
								value={selectedEndDate || ''}
								onChange={handleEndDateChange}
								size="sm"
								borderRadius='6px'
								type="date"
								focusBorderColor="gray.500"
								color={"var(--chakra-colors-secondaryGray-600)"}
								_hover={{ borderColor: 'gray.500' }}
							/>
							{dateFilterErrorMessage && !selectedEndDate && (
								<Text color="red.500" fontSize="sm" position="absolute" top="100%" mt="2px">
									{dateFilterErrorMessage}
								</Text>
							)}
						</Stat>
					</Flex>
				</Card>
				<Card py='15px' gridColumn={{ base: 'span 1', md: 'span 1' }}>
					<Flex my='auto' h='100%' align={{ base: 'center', xl: 'start' }} justify={{ base: 'center', xl: 'center' }}>
						<Button
							onClick={() => handleApplyFilters()}
							_hover={{ bg: 'gray.700' }}
							_active={{ bg: 'gray.600' }}
							_focus={{ boxShadow: 'none' }}
							backgroundColor='#688CC8'
							color="white"
							style={{
								height: '30px',
								borderRadius: '6px',
								border: 'none',
								cursor: 'pointer'
							}}
							fontSize='sm'
						>
							Applica Filtri
						</Button>
					</Flex>
				</Card>
				<Card gridColumn={{ base: 'span 1', md: 'span 1' }}>
					<Flex my='auto' h='100%' align={{ base: 'center', xl: 'start' }} justify={{ base: 'center', xl: 'center' }}>
						<Stat my='auto' position="relative">
							<Button
								onClick={() => resetFilters()}
								_hover={{ bg: 'gray.100' }}
								_active={{ bg: 'gray.200' }}
								_focus={{ boxShadow: 'none' }}
								backgroundColor='white'
								color='#688CC8'
								border='2px solid #688CC8'
								style={{
									height: '30px',
									borderRadius: '6px',
									border: 'none',
									cursor: 'pointer'
								}}
								fontSize='sm'
							>
								Azzera Filtri
							</Button>
						</Stat>
					</Flex>
				</Card>
			</SimpleGrid>

			<Card boxShadow={shadow} flexDirection='column' width={{ base: '100%', md: '96%' }} pt='25px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
				<Flex
					px='25px'
					mb="8px"
					justifyContent='space-between'
					align='center'
				>
					<Text color="#9FB50C" fontSize='22px' fontWeight='700' lineHeight='100%'>
						Lista Scadenze
					</Text>
					<Flex ml={{ base: '20px', sm: '0auto' }} >
						<ExportExcelButton keycloak={keycloak} lastQueryString={lastQueryString} />
						<ImportExcelButton keycloak={keycloak} onImportSuccess={handleExcelImportSuccess} />
						<Button
							onClick={openAddModal}
							_hover={{ bg: 'gray.700' }}
							_active={{ bg: 'gray.600' }}
							_focus={{ boxShadow: 'none' }}
							backgroundColor='#688CC8'
							color="white"
							style={{
								height: '30px',
								padding: '0px 12px',
								borderRadius: '6px',
								border: 'none',
								cursor: 'pointer',
							}}
							fontSize='xs'
							mx="1"
						>
							+ Aggiungi
						</Button>
						<Button
							onClick={handleFetchDeadlines}
							variant="ghost"
							colorScheme="blue"
							mx="1"
							p={2}
							_hover={{ bg: 'gray.200' }}
						>
							<Icon as={FaSync} w={5} h={5} />
						</Button>
					</Flex>
				</Flex>
				<Box>
					<Table variant='simple' color='gray.500' mb='24px' mt="12px" w='100%' size="sm">
						<Thead>
							{table.getHeaderGroups().map((headerGroup) => (
								<Tr key={headerGroup.id}>
									{headerGroup.headers.map((header) => {
										return (
											<Th
												key={header.id}
												colSpan={header.colSpan}
												pe='10px'
												borderColor={borderColor}
												paddingInline="0px"
											>
												<Flex
													justifyContent='space-between'
													align='center'
													fontSize={{ sm: '10px', lg: '12px' }}
													color='gray.400'>
													{flexRender(header.column.columnDef.header, header.getContext())}{{
														asc: '',
														desc: '',
													}[header.column.getIsSorted() as string] ?? null}
												</Flex>
											</Th>
										);
									})}
								</Tr>
							))}
						</Thead>
						<Tbody>
							{loading ? (
								Array.from({ length: 15 }).map((_, rowIndex) => (
									<Tr key={`loading-row-${rowIndex}`}>
										{columns.map((column, columnIndex) => (
											<Td
												key={`loading-cell-${rowIndex}-${columnIndex}`}
												pb='6px'
												fontSize={{ sm: '14px' }}
												minW={{ sm: '150px', md: '200px', lg: 'auto' }}
												borderColor='transparent'
												textAlign="center"
											>
												{rowIndex === 0 ? <Spinner size="sm" color="gray.500" /> : null}
											</Td>
										))}
									</Tr>
								))
							) : pagedDeadlines?.content?.length > 0 ? (
								<>
									{table.getRowModel().rows.map((row) => (
										<Tr
											key={row.id}
											_hover={{ bg: 'gray.100' }}
											_active={{ bg: 'gray.200' }}
											_focus={{ boxShadow: 'none' }}
										>
											{row.getVisibleCells().map((cell) => (
												<Td
													pb='2px'
													key={cell.id}
													fontSize={{ sm: '14px' }}
													minW={{ sm: '150px', md: '200px', lg: 'auto' }}
													borderColor='transparent'
												>
													{flexRender(cell.column.columnDef.cell, cell.getContext())}
												</Td>
											))}
										</Tr>
									))}
								</>
							) : (
								<Tr>
									<Td colSpan={columns.length} textAlign="center">
										Nessuna scadenza
									</Td>
								</Tr>
							)}
						</Tbody>
						<Tfoot w="100%">
							<Tr>
								<Td colSpan={8} textAlign="center">
									<Flex justifyContent="center" alignItems="center" mt={4}>
										<Button
											onClick={() => handlePageChange(currentPage - 1)}
											isDisabled={pagedDeadlines?.pageable?.pageNumber === 0}
											height="100%"
										>
											Precedente
										</Button>
										<Text mx={2}>
											Pagina {pagedDeadlines ? pagedDeadlines.pageable?.pageNumber + 1 : 0} di {pagedDeadlines ? pagedDeadlines.totalPages : 0}
										</Text>
										<Button
											onClick={() => handlePageChange(currentPage + 1)}
											isDisabled={pagedDeadlines?.pageable?.pageNumber === pagedDeadlines?.totalPages - 1}
											height="100%"
										>
											Successiva
										</Button>
										<Flex alignItems="center" mx={2}>
											<Input
												type="number"
												placeholder="Pagina"
												value={inputPageNumber}
												onChange={(e) => setInputPageNumber(e.target.value)}
												width="90px"
												mx={2}
											/>
											<Button
												onClick={() => {
													const pageNumber = parseInt(inputPageNumber, 10);
													if (!isNaN(pageNumber) && pageNumber > 0 && pageNumber <= pagedDeadlines?.totalPages) {
														handlePageChange(pageNumber);
													}
												}}
											>
												Vai
											</Button>
										</Flex>
									</Flex>
								</Td>
							</Tr>
						</Tfoot>
					</Table>
				</Box>

				{/* Add Modal */}
				<Modal isOpen={isAddModalOpen} onClose={closeAddModal}>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader color="#9FB50C">Nuova Scadenza</ModalHeader>
						<ModalCloseButton _focus={{ boxShadow: 'none' }} />
						<ModalBody>
							<Text fontWeight="bold">
								Nome Servizio <Text as="span" color="red">*</Text>
							</Text>
							<Input
								_focus={{ boxShadow: 'black' }}
								placeholder="Nome Servizio"
								width="100%"
								mb="4"
								value={serviceName}
								onChange={(e) => setServiceName(e.target.value)}
							/>
							<Text fontWeight="bold">
								Categoria <Text as="span" color="red">*</Text>
							</Text>
							<Menu>
								<MenuButton
									as={Button}
									rightIcon={<IoIosArrowDown />}
									variant='subtle'
									color="var(--chakra-colors-secondaryGray-600)"
									textAlign="left"
									fontWeight="normal"
									border="1px solid"
									borderColor="gray.200"
									borderRadius="0.375rem"
									width="100%"
									mb="4"
								>
									{categoryDetails.find((source) => source.id === selectedCategoryId)?.name || "Categoria"}
								</MenuButton>
								<MenuList textAlign="left" maxHeight="220px" overflowY="auto">
									{categoryDetails.map((source) => (
										<MenuItem _hover={{ bg: 'gray.200' }} key={source.id} onClick={() => setSelectedCategoryId(source.id)}>
											{source.name}
										</MenuItem>
									))}
								</MenuList>
							</Menu>
							<Text fontWeight="bold">
								Tipologia <Text as="span" color="red">*</Text>
							</Text>
							<Menu>
								<MenuButton
									as={Button}
									rightIcon={<IoIosArrowDown />}
									variant='subtle'
									color="var(--chakra-colors-secondaryGray-600)"
									textAlign="left"
									fontWeight="normal"
									border="1px solid"
									borderColor="gray.200"
									borderRadius="0.375rem"
									width="100%"
									mb="4"
								>
									{deadlinesTipology.find((consumer) => consumer.id === selectedTipologyId)?.name || "Tipologia"}
								</MenuButton>
								<MenuList textAlign="left" maxHeight="220px" overflowY="auto">
									{deadlinesTipology.map((consumer) => (
										<MenuItem _hover={{ bg: 'gray.200' }} key={consumer.id} onClick={() => setSelectedTipologyId(consumer.id)}>
											{consumer.name}
										</MenuItem>
									))}
								</MenuList>
							</Menu>
							<Text fontWeight="bold">
								Data Scadenza <Text as="span" color="red">*</Text>
							</Text>
							<Input
								borderRadius='6px'
								type="date"
								focusBorderColor="gray.300"
								_hover={{ borderColor: 'gray.300' }}
								color="gray.400"
								width="100%"
								mb="4"
								value={deadlineDate}
								onChange={(e) => setDeadlineDate(e.target.value)}
							/>
							<Text fontWeight="bold">Link Documentazione</Text>
							<Input
								borderRadius='6px'
								type="url"
								placeholder="Link Documentazione"
								focusBorderColor="gray.300"
								_hover={{ borderColor: 'gray.300' }}
								color="gray.400"
								width="100%"
								mb="4"
								value={documentationRefs}
								onChange={(e) => setDocumentationRefs(e.target.value)}
							/>
							<Text fontWeight="bold">Notifica Cliente</Text>
							<Flex gap={4}>
								<Menu placement="bottom" flip={false}>
									<MenuButton
										borderRadius='6px'
										as={Button}
										rightIcon={<ChevronDownIcon />}
										bg="gray.200"
										onClick={() => {
											if (emailTemplates?.length === 0) {
												fetchCustomers();
											}
										}}
									>
										{selectedCustomer ? (truncateName(selectedCustomer?.fullName, 21) || 'Cliente') : 'Cliente'}
									</MenuButton>
									<MenuList maxHeight="250px" overflowY="auto" width="350px">
										<Box p="2">
											<InputGroup>
												<Input
													placeholder="Cerca per ragione sociale..."
													value={searchedCustomer}
													onChange={(e) => setSearchedCustomer(e.target.value)}
													onKeyDown={(e) => {
														if (e.key === "Enter") {
														  fetchCustomers(searchedCustomer); // Trigger search on Enter
														}
													}}
												/>
												<InputRightElement width="3rem">
													<Button h="1.75rem" size="sm" onClick={() => fetchCustomers(searchedCustomer)}>
														<SearchIcon />
													</Button>
												</InputRightElement>
											</InputGroup>
										</Box>
										{customers?.content?.map((customer: Customer) => (
											<MenuItem key={customer.id} onClick={() => handleCustomerSelect(customer)}>
												{customer.fullName}
											</MenuItem>
										))}
									</MenuList>
								</Menu>
								<Menu placement="bottom" preventOverflow={false} flip={false}>
									<MenuButton
										borderRadius='6px'
										as={Button} rightIcon={<ChevronDownIcon />}
										bg="gray.200"
										onClick={() => {
											if (emailTemplates?.length === 0) {
												fetchEmailTemplates();
											}
										}}
									>
										{selectedEmailTemplate?.name || 'Modello Email'}
									</MenuButton>
									<MenuList maxHeight="250px" overflowY="auto">
										<Box p="2">
											<Input
												placeholder="Cerca modello..."
												value={searchedEmailTemplate}
												onChange={(e) => setSearchedEmailTemplate(e.target.value)}
											/>
										</Box>
										{filteredEmailTemplate.length === 0 ? (
											<Spinner size="md" />
										) : (
											filteredEmailTemplate.map((template: EmailTemplate) => (
												<MenuItem key={template.id} onClick={() => handleEmailTemplateSelect(template)}>
													{template.name}
												</MenuItem>
											))
										)}
									</MenuList>
								</Menu>
							</Flex>
							<Text fontWeight="bold" mt='4'>Avvisi di scadenza</Text>
							<Checkbox

								mb="2"
								name="oneDayBefore"
								isChecked={notifications.oneDayBefore}
								onChange={handleCheckboxChange}
							>
								Avviso di scadenza un giorno prima
							</Checkbox>
							<Checkbox
								mb="2"
								name="oneWeekBefore"
								isChecked={notifications.oneWeekBefore}
								onChange={handleCheckboxChange}
							>
								Avviso di scadenza una settimana prima
							</Checkbox>
							<Checkbox
								mb="4"
								name="oneMonthBefore"
								isChecked={notifications.oneMonthBefore}
								onChange={handleCheckboxChange}
							>
								Avviso di scadenza un mese prima
							</Checkbox>
						</ModalBody>
						<ModalFooter>
							<Tooltip
								label="Inserisci Nome Servizio, Categoria, Tipologia e Data Scadenza per salvare"
								isDisabled={!isSaveDisabled}
								shouldWrapChildren
							>
								<Button
									colorScheme={isSaveDisabled ? "red" : "#688CC8"}
									backgroundColor={isSaveDisabled ? 'red.500' : '#688CC8'}
									color="white"
									mr={3}
									onClick={addDeadline}
									isLoading={loading}
									disabled={isSaveDisabled}
								>
									Salva
								</Button>
							</Tooltip>
							<Button colorScheme="gray" onClick={closeAddModal}>Annulla</Button>
						</ModalFooter>
					</ModalContent>
				</Modal>
				{/* Edit Modal */}
				<Modal isOpen={!!editId} onClose={closeEditModal}>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader color="#9FB50C">Modifica Scadenza</ModalHeader>
						<ModalCloseButton _focus={{ boxShadow: 'none' }} />
						<ModalBody>
							<Text fontWeight="bold">
								Nome Servizio <Text as="span" color="red">*</Text>
							</Text>
							<Input
								_focus={{ boxShadow: 'black' }}
								placeholder="Nome Servizio"
								width="100%"
								mb="4"
								value={newServiceName}
								onChange={(e) => setNewServiceName(e.target.value)}
							/>
							<Text fontWeight="bold">
								Categoria <Text as="span" color="red">*</Text>
							</Text>
							<Menu>
								<MenuButton
									as={Button}
									rightIcon={<IoIosArrowDown />}
									variant='subtle'
									color="var(--chakra-colors-secondaryGray-600)"
									textAlign="left"
									fontWeight="normal"
									border="1px solid"
									borderColor="gray.200"
									borderRadius="0.375rem"
									width="100%"
									mb="4"
								>
									{categoryDetails.find((category) => category.id === newSelectedCategoryId)?.name || "Categoria"}
								</MenuButton>
								<MenuList textAlign="left" maxHeight="220px" overflowY="auto">
									{categoryDetails.map((category) => (
										<MenuItem _hover={{ bg: 'gray.200' }} key={category.id} onClick={() => setNewSelectedCategoryId(category.id)}>
											{category.name}
										</MenuItem>
									))}
								</MenuList>
							</Menu>
							<Text fontWeight="bold">
								Tipologia <Text as="span" color="red">*</Text>
							</Text>
							<Menu>
								<MenuButton
									as={Button}
									rightIcon={<IoIosArrowDown />}
									variant='subtle'
									color="var(--chakra-colors-secondaryGray-600)"
									textAlign="left"
									fontWeight="normal"
									border="1px solid"
									borderColor="gray.200"
									borderRadius="0.375rem"
									width="100%"
									mb="4"
								>
									{deadlinesTipology.find((tipology) => tipology.id === newSelectedTipologyId)?.name || "Tipologia"}
								</MenuButton>
								<MenuList textAlign="left" maxHeight="220px" overflowY="auto">
									{deadlinesTipology.map((tipology) => (
										<MenuItem _hover={{ bg: 'gray.200' }} key={tipology.id} onClick={() => setNewSelectedTipologyId(tipology.id)}>
											{tipology.name}
										</MenuItem>
									))}
								</MenuList>
							</Menu>
							<Text fontWeight="bold" mt='2%'>
								Data Scadenza <Text as="span" color="red">*</Text>
							</Text>
							<Input
								borderRadius='6px'
								type="date"
								focusBorderColor="gray.300"
								_hover={{ borderColor: 'gray.300' }}
								color="gray.400"
								width="100%"
								mb="4"
								value={newDeadlineDate}
								onChange={(e) => setNewDeadlineDate(e.target.value)}
							/>
							<Text fontWeight="bold" mt='2%'>Link Documentazione</Text>
							<Input
								borderRadius='6px'
								type="url"
								placeholder="Link Documentazione"
								focusBorderColor="gray.300"
								_hover={{ borderColor: 'gray.300' }}
								color="gray.400"
								width="100%"
								mb="4"
								value={documentationRefs}
								onChange={(e) => setDocumentationRefs(e.target.value)}
							/>
							<Text fontWeight="bold">Notifica Cliente</Text>
							<Flex gap={4}>
								<Menu placement="bottom" flip={false}>
									<MenuButton
										borderRadius='6px'
										as={Button}
										rightIcon={<ChevronDownIcon />}
										bg="gray.200"
										onClick={() => {
											if (emailTemplates?.length === 0) {
												fetchCustomers();
											}
										}}
									>
										{selectedCustomer ? (truncateName(selectedCustomer?.fullName, 21) || 'Cliente') : 'Cliente'}
									</MenuButton>
									<MenuList maxHeight="250px" overflowY="auto" width="350px">
										<Box p="2">
											<InputGroup>
												<Input
													placeholder="Cerca per ragione sociale..."
													value={searchedCustomer}
													onChange={(e) => setSearchedCustomer(e.target.value)} // Update the search term
													onKeyDown={(e) => {
														if (e.key === "Enter") {
														  fetchCustomers(searchedCustomer);
														}
													}}
												/>
												<InputRightElement width="3rem">
													<Button h="1.75rem" size="sm" onClick={() => fetchCustomers(searchedCustomer)}>
														<SearchIcon />
													</Button>
												</InputRightElement>
											</InputGroup>
										</Box>
										{customers?.content?.map((customer: Customer) => (
											<MenuItem key={customer.id} onClick={() => handleCustomerSelect(customer)}>
												{customer.fullName}
											</MenuItem>
										))}
									</MenuList>
								</Menu>
								<Menu placement="bottom" preventOverflow={false} flip={false}>
									<MenuButton
										borderRadius='6px'
										as={Button}
										rightIcon={<ChevronDownIcon />}
										bg="gray.200"
										onClick={() => {
											if (emailTemplates?.length === 0) {
												fetchEmailTemplates();
											}
										}}
									>
										{selectedEmailTemplate?.name || 'Modello Email'}
									</MenuButton>
									<MenuList maxHeight="250px" overflowY="auto">
										<Box p="2">
											<Input
												placeholder="Cerca modello..."
												value={searchedEmailTemplate}
												onChange={(e) => setSearchedEmailTemplate(e.target.value)}
											/>
										</Box>
										{filteredEmailTemplate.length === 0 ? (
											<Spinner size="md" />
										) : (
											filteredEmailTemplate.map((template: EmailTemplate) => (
												<MenuItem key={template.id} onClick={() => handleEmailTemplateSelect(template)}>
													{template.name}
												</MenuItem>
											))
										)}
									</MenuList>
								</Menu>
							</Flex>
							<Text fontWeight="bold" mt='4'>Avvisi di scadenza</Text>
							<Checkbox
								name="oneDayBefore"
								isChecked={notifications.oneDayBefore}
								onChange={handleCheckboxChange}
								mb="2"
								mt='2'
							>
								Avviso di scadenza un giorno prima
							</Checkbox>
							<Checkbox
								name="oneWeekBefore"
								isChecked={notifications.oneWeekBefore}
								onChange={handleCheckboxChange}
								mb="2"
							>
								Avviso di scadenza una settimana prima
							</Checkbox>
							<Checkbox
								name="oneMonthBefore"
								isChecked={notifications.oneMonthBefore}
								onChange={handleCheckboxChange}
								mb="4"
							>
								Avviso di scadenza un mese prima
							</Checkbox>
						</ModalBody>
						<ModalFooter>
							<Tooltip
								label="Inserisci Nome Servizio, Categoria, Tipologia e Data Scadenza per salvare"
								isDisabled={!isSaveDisabled}
								shouldWrapChildren
							>
								<Button
									colorScheme={isSaveDisabled ? "red" : "#688CC8"}
									backgroundColor={isSaveDisabled ? 'red.500' : '#688CC8'}
									color="white"
									mr={3}
									onClick={editDeadline}
									isLoading={loading}
									disabled={isSaveDisabled}
								>
									Modifica
								</Button>
							</Tooltip>
							<Button colorScheme="gray" onClick={closeEditModal}>Annulla</Button>
						</ModalFooter>
					</ModalContent>
				</Modal>
				{/* Modale di copia */}
				<Modal isOpen={!!copyId} onClose={closeCopyModal}>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader color="#9FB50C">Copia Scadenza</ModalHeader>
						<ModalCloseButton _focus={{ boxShadow: 'none' }} />
						<ModalBody>
							<Text fontWeight="bold">
								Nome Servizio <Text as="span" color="red">*</Text>
							</Text>
							<Input
								_focus={{ boxShadow: 'black' }}
								placeholder="Nome Servizio"
								width="100%"
								mb="4"
								value={newServiceName}
								onChange={(e) => setNewServiceName(e.target.value)}
							/>
							<Text fontWeight="bold">
								Categoria <Text as="span" color="red">*</Text>
							</Text>
							<Menu>
								<MenuButton
									as={Button}
									rightIcon={<IoIosArrowDown />}
									variant='subtle'
									color="var(--chakra-colors-secondaryGray-600)"
									textAlign="left"
									fontWeight="normal"
									border="1px solid"
									borderColor="gray.200"
									borderRadius="0.375rem"
									width="100%"
									mb="4"
								>
									{categoryDetails.find((source) => source.id === newSelectedCategoryId)?.name || "Categoria"}
								</MenuButton>
								<MenuList textAlign="left" maxHeight="220px" overflowY="auto">
									{categoryDetails.map((source) => (
										<MenuItem _hover={{ bg: 'gray.200' }} key={source.id} onClick={() => setNewSelectedCategoryId(source.id)}>
											{source.name}
										</MenuItem>
									))}
								</MenuList>
							</Menu>
							<Text fontWeight="bold">
								Tipologia <Text as="span" color="red">*</Text>
							</Text>
							<Menu>
								<MenuButton
									as={Button}
									rightIcon={<IoIosArrowDown />}
									variant='subtle'
									color="var(--chakra-colors-secondaryGray-600)"
									textAlign="left"
									fontWeight="normal"
									border="1px solid"
									borderColor="gray.200"
									borderRadius="0.375rem"
									width="100%"
									mb="4"
								>
									{deadlinesTipology.find((consumer) => consumer.id === newSelectedTipologyId)?.name || "Tipologia"}
								</MenuButton>
								<MenuList textAlign="left" maxHeight="220px" overflowY="auto">
									{deadlinesTipology.map((consumer) => (
										<MenuItem _hover={{ bg: 'gray.200' }} key={consumer.id} onClick={() => setNewSelectedTipologyId(consumer.id)}>
											{consumer.name}
										</MenuItem>
									))}
								</MenuList>
							</Menu>
							<Text fontWeight="bold">
								Data Scadenza <Text as="span" color="red">*</Text>
							</Text>
							<Input
								borderRadius='6px'
								type="date"
								focusBorderColor="gray.300"
								_hover={{ borderColor: 'gray.300' }}
								color="gray.400"
								width="100%"
								mb="4"
								value={newDeadlineDate}
								onChange={(e) => setNewDeadlineDate(e.target.value)}
							/>
							<Text fontWeight="bold">Link Documentazione</Text>
							<Input
								borderRadius='6px'
								type="url"
								placeholder="Link Documentazione"
								focusBorderColor="gray.300"
								_hover={{ borderColor: 'gray.300' }}
								color="gray.400"
								width="100%"
								mb="4"
								value={documentationRefs}
								onChange={(e) => setDocumentationRefs(e.target.value)}
							/>
							<Text fontWeight="bold">Notifica Cliente</Text>
							<Flex gap={4}>
								<Menu placement="bottom" flip={false}>
									<MenuButton
										borderRadius='6px'
										as={Button}
										rightIcon={<ChevronDownIcon />}
										bg="gray.200"
										onClick={() => {
											if (emailTemplates?.length === 0) {
												fetchCustomers();
											}
										}}
									>
										{selectedCustomer ? (truncateName(selectedCustomer?.fullName, 21) || 'Cliente') : 'Cliente'}
									</MenuButton>
									<MenuList maxHeight="250px" overflowY="auto" width="350px">
										<Box p="2">
											<InputGroup>
												<Input
													placeholder="Cerca per ragione sociale..."
													value={searchedCustomer}
													onChange={(e) => setSearchedCustomer(e.target.value)} // Update the search term
													onKeyDown={(e) => {
														if (e.key === "Enter") {
														  fetchCustomers(searchedCustomer); // Trigger search on Enter
														}
													}}
												/>
												<InputRightElement width="3rem">
													<Button h="1.75rem" size="sm" onClick={() => fetchCustomers(searchedCustomer)}>
														<SearchIcon />
													</Button>
												</InputRightElement>
											</InputGroup>
										</Box>
										{customers?.content?.map((customer: Customer) => (
											<MenuItem key={customer.id} onClick={() => handleCustomerSelect(customer)}>
												{customer.fullName}
											</MenuItem>
										))}
									</MenuList>
								</Menu>
								<Menu placement="bottom" preventOverflow={false} flip={false}>
									<MenuButton
										borderRadius='6px'
										as={Button}
										rightIcon={<ChevronDownIcon />}
										bg="gray.200"
										onClick={() => {
											if (emailTemplates?.length === 0) {
												fetchEmailTemplates();
											}
										}}
									>
										{selectedEmailTemplate?.name || 'Modello Email'}
									</MenuButton>
									<MenuList maxHeight="250px" overflowY="auto">
										<Box p="2">
											<Input
												placeholder="Cerca modello..."
												value={searchedEmailTemplate}
												onChange={(e) => setSearchedEmailTemplate(e.target.value)}
											/>
										</Box>
										{filteredEmailTemplate.length === 0 ? (
											<Spinner size="md" />
										) : (
											filteredEmailTemplate.map((template: EmailTemplate) => (
												<MenuItem key={template.id} onClick={() => handleEmailTemplateSelect(template)}>
													{template.name}
												</MenuItem>
											))
										)}
									</MenuList>
								</Menu>
							</Flex>
							<Text fontWeight="bold" mt={4}>Avvisi di scadenza</Text>
							<Checkbox
								name="oneDayBefore"
								isChecked={notifications.oneDayBefore}
								onChange={handleCheckboxChange}
								mb="2"
							>
								Avviso di scadenza un giorno prima
							</Checkbox>
							<Checkbox
								name="oneWeekBefore"
								isChecked={notifications.oneWeekBefore}
								onChange={handleCheckboxChange}
								mb="2"
							>
								Avviso di scadenza una settimana prima
							</Checkbox>
							<Checkbox
								name="oneMonthBefore"
								isChecked={notifications.oneMonthBefore}
								onChange={handleCheckboxChange}
								mb="4"
							>
								Avviso di scadenza un mese prima
							</Checkbox>
						</ModalBody>
						<ModalFooter>
							{!newServiceName || !newDeadlineDate ? (
								<Tooltip
									label="Inserisci Nome Servizio, Data Scadenza e Link Documentazione per copiare"
									shouldWrapChildren
								>
									<Button
										colorScheme="red"
										backgroundColor="red.500"
										color="white"
										mr={3}
										onClick={copyDeadline}
										isLoading={loading}
										disabled
									>
										Copia
									</Button>
								</Tooltip>
							) : (
								<Button
									colorScheme="#688CC8"
									backgroundColor="#688CC8"
									color="white"
									mr={3}
									onClick={copyDeadline}
									isLoading={loading}
								>
									Copia
								</Button>
							)}

							<Button colorScheme="gray" onClick={closeCopyModal}>Annulla</Button>
						</ModalFooter>
					</ModalContent>
				</Modal>
				{/* Conferma eliminazione */}
				<Modal isOpen={isDeleteModalOpen} onClose={closeDeleteModal}>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader color="#9FB50C">Conferma Eliminazione</ModalHeader>
						<ModalCloseButton _focus={{ boxShadow: 'none' }} />
						<ModalBody>
							Sei sicuro di voler eliminare questa scadenza?
						</ModalBody>
						<ModalFooter>
							<Button colorScheme="red" mr={3} onClick={confirmDelete}>
								Elimina
							</Button>
							<Button colorScheme="gray" onClick={closeDeleteModal}>
								Annulla
							</Button>
						</ModalFooter>
					</ModalContent>
				</Modal>
			</Card>
		</Card>
	);
}