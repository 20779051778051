import {
	Avatar,
	Box,
	Flex, Icon,
	Menu, MenuButton, MenuItem, MenuList, Text, useColorModeValue
} from '@chakra-ui/react';
import { useKeycloak } from '@react-keycloak/web';
import { useState } from "react";
import { FiLogOut } from 'react-icons/fi';
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { MdSettings } from "react-icons/md";
import { useHistory } from 'react-router-dom';

export default function SidebarDocs(props: { onClose: () => void }) {

	const [toggleMenuList, setMenuList] = useState(false);
	const { keycloak } = useKeycloak();
    const history = useHistory();

	const handleSideMenuListClick = () => {
		setMenuList(prevState => !prevState);
	};

	const shadow = useColorModeValue(
		'14px 17px 40px 4px rgba(112, 144, 176, 0.3)',
		'14px 17px 40px 4px rgba(112, 144, 176, 0.1)'
	);

	const handleNavigation = () => {
        history.push('/admin/configurazioni');
        setMenuList(false);
    };

	const handleLogout = () => {
		keycloak.logout();
		localStorage.removeItem("kc-token");
	};

	return (
		<Flex>
			<Menu>
				<Box p='0px' mt='12px'>
					<Avatar
						_hover={{ cursor: 'pointer' }}
						color='white'
						backgroundColor='primary'
						marginLeft='0px'
						name='R'
						bg='#11047A'
						size='sm'
						w='40px'
						h='40px'
					/>
				</Box>
				<Box>
					<Flex direction='column' mb='0px' justify='center' px='6px' pt='14px'>
						<Text
							fontSize={{ base: 'lg', xl: '14px' }}
							color='primary'
							fontWeight='bold'
							lineHeight='120%'
						>
							Admin
						</Text>
						<Text fontSize='12px' color={'gray.500'} mb='14px' align='left'>
							Rizzi
						</Text>
					</Flex>
				</Box>

				<MenuButton
					mt='25px'
					ml={{ base: '45%', md: '30%' }}
					bg='transparent'
					p='0px'
					minW='unset'
					minH='unset'
					h='18px'
					w='max-content'
					onClick={handleSideMenuListClick}>
					<Icon
						me='10px'
						h='18px'
						w='18px'
						color={"black"}
						as={toggleMenuList === true ? IoIosArrowUp : IoIosArrowDown}
					/>
				</MenuButton>
				<MenuList p='0px' mb='10px' borderRadius='15px' border='none' boxShadow={shadow}>
					<Flex flexDirection='column' p='10px'>
						<MenuItem
							_hover={{ bg: 'none' }}
							_focus={{ bg: 'none' }}
							borderRadius='8px'
							px='14px'
							onClick={handleNavigation}>
							<Icon as={MdSettings} color='black' />
							<Text ml="10px" fontSize='sm' color='black'>Configurazioni</Text>
						</MenuItem>
						<MenuItem
							_hover={{ bg: 'none' }}
							_focus={{ bg: 'none' }}
							color='#9FB50C'
							borderRadius='8px'
							px='14px'
							onClick={handleLogout}>
							<Icon as={FiLogOut} color='#9FB50C' />
							<Text ml="10px" fontSize='sm' color='#9FB50C'>Logout</Text>
						</MenuItem>
					</Flex>
				</MenuList>
			</Menu>
		</Flex>
	);
}
