import {
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Icon,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { IoMenuOutline } from 'react-icons/io5';
import { useEffect, useState } from 'react';
import Content from './components/Content';

function SidebarResponsive(props: { routes: any[] }) {
  const { routes } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isScrolled, setIsScrolled] = useState(false);

  let sidebarBackgroundColor = useColorModeValue('white', 'navy.800');
  let menuColor = useColorModeValue('gray.400', 'white');

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleToggle = () => {
    if (isOpen) {
      onClose();
    } else {
      onOpen();
    };
  };

  const filteredRoutes = routes.filter(
    (route) => route.path !== '/' && route.path !== '/configurazioni'
  );

  return (
    <>
      <Flex
        alignItems="center"
        position="fixed"
        top="0"
        left="0"
        right="0"
        zIndex="999"
        bg={isScrolled ? sidebarBackgroundColor : 'transparent'}
        px="20px"
        py="10px"
        transition="background-color 0.3s ease"
      >
        <Flex onClick={handleToggle} cursor="pointer">
          <Icon as={IoMenuOutline} color={menuColor} boxSize={6} />
        </Flex>
      </Flex>
      <Drawer isOpen={isOpen} onClose={onClose} placement="left">
        <DrawerOverlay />
        <DrawerContent bg={sidebarBackgroundColor}>
          <DrawerCloseButton onClick={onClose} _focus={{ boxShadow: 'none' }} _hover={{ boxShadow: 'none' }} />
          <Content routes={filteredRoutes} onClose={onClose} />
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default SidebarResponsive;