import { NavLink, useLocation } from 'react-router-dom';
import { Box, Flex, HStack, Text, useBreakpointValue, useColorModeValue } from '@chakra-ui/react';

export function SidebarLinks(props: {
    routes: RoutesType[];
    onClose: () => void;
}) {
    //   Chakra color mode
    let location = useLocation();
    let activeColor = useColorModeValue('gray.700', 'white');
    let inactiveColor = useColorModeValue('secondaryGray.600', 'secondaryGray.600');
    let activeIcon = useColorModeValue('gray.700', 'white');
    let textColor = useColorModeValue('secondaryGray.500', 'white');
    let brandColor = useColorModeValue('gray.600', 'brand.400');

    const { routes, onClose } = props;

    // verifies if routeName is the one active (in browser input)
    const activeRoute = (routeName: string) => {
        return location.pathname.includes(routeName);
    };

    const isMobile = useBreakpointValue({ base: true, lg: false });

    const handleClick = () => {
        if (isMobile && onClose) {
            onClose();
        }
    };

    // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
    const createLinks = (
        routes: RoutesType[],
    ) => {
        return routes.map(
            (
                route: RoutesType,
                index: number
            ) => {
                if (route.layout === '/admin' || route.layout === '/auth') {
                    return (
                        <NavLink key={index} to={route.layout + route.path}  onClick={handleClick}>
                            {route.icon ? (
                                <Box>
                                    <HStack
                                        spacing={activeRoute(route.path.toLowerCase()) ? '22px' : '26px'}
                                        py='5px'
                                        ps='10px'>
                                        <Flex w='100%' alignItems='center' justifyContent='center'>
                                            <Box
                                                color={activeRoute(route.path.toLowerCase()) ? activeIcon : textColor}
                                                me='18px'>
                                                {route.icon}
                                            </Box>
                                            <Text
                                                me='auto'
                                                color={activeRoute(route.path.toLowerCase()) ? activeColor : textColor}
                                                fontWeight={activeRoute(route.path.toLowerCase()) ? 'bold' : 'normal'}>
                                                {route.name}
                                            </Text>
                                        </Flex>
                                        <Box
                                            h='36px'
                                            w='4px'
                                            bg={activeRoute(route.path.toLowerCase()) ? brandColor : 'transparent'}
                                            borderRadius='5px'
                                        />
                                    </HStack>
                                </Box>
                            ) : (
                                <Box>
                                    <HStack
                                        spacing={activeRoute(route.path.toLowerCase()) ? '22px' : '26px'}
                                        py='5px'
                                        ps='10px'>
                                        <Text
                                            me='auto'
                                            color={activeRoute(route.path.toLowerCase()) ? activeColor : inactiveColor}
                                            fontWeight={activeRoute(route.path.toLowerCase()) ? 'bold' : 'normal'}>
                                            {route.name}
                                        </Text>
                                        <Box h='36px' w='4px' bg='brand.400' borderRadius='5px' />
                                    </HStack>
                                </Box>
                            )}
                        </NavLink>
                    );
                }
            }
        );
    };
    //  BRAND
    return <>{createLinks(routes)}</>
}

export default SidebarLinks;