import {
    Button
} from '@chakra-ui/react';
import Keycloak from 'keycloak-js';
import { toast } from 'react-toastify';
import { fetchWithHandling } from 'utils/common-utils';

export default function ImportExcelButton({
    keycloak,
    lastQueryString
}: {
    keycloak: Keycloak;
    lastQueryString: string
}) {

    const jwtToken = localStorage.getItem('kc-token');

    const exportExcel = async () => {
		try {
			toast.info("Export in corso...", { position: "bottom-right" });

			let uri = `${process.env.REACT_APP_BACKEND_API_PREFIX}/api/v1/deadlines/export-excel`;
			// Aggiungi eventuali query string se necessario
			let queryString = lastQueryString ? lastQueryString : '';

			const response = await fetchWithHandling(
				uri + queryString,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${jwtToken}`,
					},
				},
				() => {
					toast.error(
						"Sessione scaduta. Effettua nuovamente l'accesso.", {
						position: "bottom-right",
						onClose: () => {
							keycloak.logout();
							localStorage.removeItem("kc-token");
						}
					}
					);
				},
				true
			);

			if (response) {
			// Gestione risposta corretta
			const blob = await response.blob();
			const url = window.URL.createObjectURL(new Blob([blob]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'scadenze.xlsx'); // Nome del file
			document.body.appendChild(link);
			link.click();
			link.parentNode.removeChild(link);

			toast.success("Export completato!", { position: "bottom-right" });
		}
		} catch (error) {
			// Gestione degli errori non previsti
			console.error('Errore durante l\'esportazione:', error);
			toast.error("Errore durante l'esportazione del file. Riprova più tardi.", { position: "bottom-right" });
		}
	};

    return (
        <>
           <Button
							_hover={{ bg: 'gray.100', borderColor: '#688CC8' }}
							_active={{ bg: 'gray.50', borderColor: '#688CC8' }}
							_focus={{ boxShadow: 'none' }}
							backgroundColor='white'
							color='#688CC8'
							border="1px solid #688CC8"
							style={{
								height: '30px',
								padding: '0px 12px',
								borderRadius: '6px',
								cursor: 'pointer',
							}}
							fontSize='xs'
							mx="1"
							onClick={exportExcel}
						>
							Esporta Excel
						</Button> 
        </>
    );
};