import {
  Box, Button, Flex, FormControl, Heading, Text
} from "@chakra-ui/react";
import Brand from "components/sidebar/components/Brand";
import { FaKey } from "react-icons/fa";
import { useHistory } from 'react-router-dom';
import AuthCover from "../../utils/assets/img/auth-cover.png";
import DefaultAuth from "../../utils/layouts/auth/component/Default";
import { useKeycloak } from '@react-keycloak/web';
import { useEffect } from 'react';

function SignIn() {
  const textColorSecondary = "gray.400";
  const { keycloak } = useKeycloak();
  const history = useHistory();

  const handleLogin = () => {
    keycloak.login();
  };

  useEffect(() => {
    if (keycloak.authenticated) {
      history.push('/');
    }
  }, [keycloak.authenticated, history]);

  return (
    <DefaultAuth illustrationBackground={AuthCover} image={AuthCover}>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        me='auto'
        h='100%'
        flexDirection='column'
        justifyContent="center"
      >
        <Brand />
        <Flex
          maxW={{ base: "100%", md: "max-content" }}
          w='100%'
          mx={{ base: "auto", lg: "0px" }}
          me='auto'
          h='100%'
          alignItems='start'
          justifyContent='center'
          mb={{ base: "30px", md: "60px" }}
          px={{ base: "25px", md: "0px" }}
          mt={{ base: "40px", md: "14vh" }}
          flexDirection='column'>
          <Box me='auto'>
            <Heading color='primary' fontSize='36px' mb='10px'>
              Login
            </Heading>
            <Text
              mb='36px'
              ms='4px'
              color={textColorSecondary}
              fontWeight='400'
              fontSize='md'>
              Effettua l'accesso con il tuo account!
            </Text>
          </Box>
          <Flex
            zIndex='2'
            direction='column'
            w={{ base: "100%", md: "420px" }}
            maxW='100%'
            background='transparent'
            borderRadius='15px'
            mx={{ base: "auto", lg: "unset" }}
            me='auto'
            mb={{ base: "20px", md: "auto" }}>
            <FormControl>
              <Button
                fontSize='md'
                fontWeight='600'
                variant="primary"
                w='100%'
                h='50px'
                mb='24px'
                bg="primary"
                color='white'
                onClick={handleLogin}
              >
                <Flex align="center">
                  <FaKey style={{ marginRight: '8px' }} />
                  Accedi Ora
                </Flex>
              </Button>
            </FormControl>
          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>

  );
}

export default SignIn;
