import { Flex, Spinner } from '@chakra-ui/react';
import { ChakraProvider } from '@chakra-ui/react';
import theme from '../utils/theme/theme';

const LoadingSpinner = () => (
  <ChakraProvider theme={theme}>
  <Flex justify="center" align="center" height="100vh">
    <Spinner size="xl" color="primary" />
  </Flex>
  </ChakraProvider>
);

export default LoadingSpinner;