import {
	Box, Button, Flex, FormControl, FormErrorMessage, Icon, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid, Spinner, Stat, StatLabel, Table, Tbody, Td, Text, Tfoot, Th, Thead, Tooltip, Tr, useColorModeValue
} from '@chakra-ui/react';
import {
	createColumnHelper,
	flexRender,
	getCoreRowModel, getFilteredRowModel, getPaginationRowModel,
	getSortedRowModel, useReactTable
} from '@tanstack/react-table';
import Card from 'components/card/Card';
import Keycloak from 'keycloak-js';
import { useEffect, useState } from "react";
import { FaSync } from 'react-icons/fa';
import { MdOutlineDeleteOutline, MdOutlineEdit } from 'react-icons/md';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchWithHandling, validateEmail, validateItalianTaxCode, validateItalianVAT } from 'utils/common-utils';
import { Customer, PagedCustomers } from "../../../utils/interfaces";
import './pagination.css';

export default function CustomersTable({
	keycloak,
}: {
	keycloak: Keycloak;
}) {
	const jwtToken = localStorage.getItem('kc-token');

	// Box ricerca
	const [typedFullName, setTypedFullName] = useState<string>('');
	const [typedVatNumber, setTypedVatNumber] = useState<string>('');
	const [typedTaxCode, setTypedTaxCode] = useState<string>('');
	const [typedEmail, setTypedEmail] = useState<string>('');
	const [typedAddress, setTypedAddress] = useState<string>('');

	const [loading, setLoading] = useState(true);
	const [isAddModalOpen, setIsAddModalOpen] = useState(false);

	// Add Modal
	const [fullName, setFullName] = useState('');
	const [vatNumber, setVatNumber] = useState(''); // P.IVA
	const [taxCode, setTaxCode] = useState(''); // Codice Fiscale
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [address, setAddress] = useState('');
	const [addressCity, setAddressCity] = useState('');
	const [addressZip, setAddressZip] = useState('');
	const [addressState, setAddressState] = useState('');

	// Edit Modal
	const [editId, setEditId] = useState(null);

	// Pagination
	const [pagedCustomers, setPagedCustomers] = useState<PagedCustomers | null>(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [inputPageNumber, setInputPageNumber] = useState('');

	// Error
	const [backendError, setBackendError] = useState(false);
	const [isVATError, setIsVATError] = useState(false);
	const [isTaxCodeError, setIsTaxCodeError] = useState(false);
	const [isEmailError, setIsEmailError] = useState(false);

	// Color
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

	const shadow = useColorModeValue(
		'14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
		'14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
	);

	// Table
	const columnHelper = createColumnHelper<Customer>();

	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
	const [selectedId, setSelectedId] = useState(null);

	useEffect(() => {
		fetchCustomers(currentPage);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPage]);

	useEffect(() => {
		if (backendError) {
			toast.error(
				"Errore durante la chiamata al Server, perfavore riprova.", {
				position: "bottom-right",
			});
		}
	}, [backendError]);

	const handlePageChange = (newPage: number) => {
		if (newPage >= 0 && newPage <= pagedCustomers.totalPages) {
			setCurrentPage(newPage);
		}
	};

	const buildURI = (
		pageNumber: number
	) => {
		let baseUrl = `${process.env.REACT_APP_BACKEND_API_PREFIX}/api/v1/customers/paginated`;
		let queryParams = [];

		if (pageNumber) {
			queryParams.push(`pageNumber=${pageNumber - 1}`);
		}

		if (typedFullName !== '') {
			queryParams.push(`fullName=${typedFullName}`);
		}

		if (typedVatNumber !== '') {
			queryParams.push(`vatNumber=${typedVatNumber}`);
		}

		if (typedTaxCode !== '') {
			queryParams.push(`taxCode=${typedTaxCode}`);
		}

		if (typedEmail !== '') {
			queryParams.push(`email=${typedEmail}`);
		}

		if (typedAddress !== '') {
			queryParams.push(`address=${typedAddress}`);
		}

		let queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
		const uri = baseUrl + queryString;

		return uri;
	};

	const fetchCustomers = async (pageNumber?: number) => {
		setLoading(true);
		try {
			const uri = buildURI(pageNumber);
			const jsonData = await fetchWithHandling(
				uri,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${jwtToken}`,
					},
				},
				() => {
					toast.error(
						"Sessione scaduta. Effettua nuovamente l'accesso.", {
						position: "bottom-right",
						onClose: () => {
							keycloak.logout();
							localStorage.removeItem("kc-token");
						}
					}
					);
				}
			);

			if (jsonData) {
				setPagedCustomers(jsonData);
			}

		} catch (error) {
			setBackendError(true); // Imposto un errore di backend se qualcosa va storto
		} finally {
			setLoading(false); // Disattivo lo stato di caricamento
		}
	};

	const resetFilters = async () => {
		setTypedFullName('');
		setTypedVatNumber('');
		setTypedTaxCode('');
		setTypedEmail('');
		setTypedAddress('');

		setLoading(true);
		try {
			const uri = `${process.env.REACT_APP_BACKEND_API_PREFIX}/api/v1/customers/paginated`;
			const jsonData = await fetchWithHandling(
				uri,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${jwtToken}`,
					},
				},
				() => {
					toast.error(
						"Sessione scaduta. Effettua nuovamente l'accesso.", {
						position: "bottom-right",
						onClose: () => {
							keycloak.logout();
							localStorage.removeItem("kc-token");
						}
					}
					);
				}
			);

			if (jsonData) {
				setPagedCustomers(jsonData);
			}

		} catch (error) {
			setBackendError(true); // Imposto un errore di backend se qualcosa va storto
		} finally {
			setLoading(false); // Disattivo lo stato di caricamento
		}
	};

	const handleApplyFilters = () => {
		fetchCustomers(currentPage);
	};

	const columns = [
		columnHelper.accessor('fullName', {
			id: 'fullName',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					paddingInlineStart='var(--chakra-space-6)'
					marginRight='auto'
					fontSize={{ sm: '10px', lg: '14px' }}
					textTransform="none"
					color='gray.400'>
					Ragione Sociale
				</Text>
			),
			cell: (info: any) => (
				<Flex align='center'>
					<Text
						color={textColor}
						fontSize='xs'
						fontWeight='700'
					>
						{info.getValue()}
					</Text>
				</Flex>
			)
		}),
		columnHelper.accessor('vatNumber', {
			id: 'vatNumber',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					marginLeft='auto'
					marginRight='auto'
					fontSize={{ sm: '10px', lg: '14px' }}
					textTransform="none"
					color='gray.400'
					textAlign="center"
				>
					P. IVA
				</Text>
			),
			cell: (info: any) => (
				<Flex align='center'>
					<Text textAlign="center" color={textColor} fontSize='xs' fontWeight='700' marginLeft='auto' marginRight='auto'>
						{info.getValue()}
					</Text>
				</Flex>
			)
		}),
		columnHelper.accessor('taxCode', {
			id: 'taxCode',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					marginLeft='auto'
					marginRight='auto'
					fontSize={{ sm: '10px', lg: '14px' }}
					textTransform="none"
					color='gray.400'
					textAlign="center"
				>
					Codice Fiscale
				</Text>
			),
			cell: (info: any) => (
				<Flex align='center'>
					<Text textAlign="center" color={textColor} fontSize='xs' fontWeight='700' marginLeft='auto' marginRight='auto'>
						{info.getValue()}
					</Text>
				</Flex>
			)
		}),
		columnHelper.accessor('email', {
			id: 'email',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					marginLeft='auto'
					marginRight='auto'
					fontSize={{ sm: '10px', lg: '14px' }}
					textTransform="none"
					color='gray.400'
					textAlign="center"
				>
					Email
				</Text>
			),
			cell: (info: any) => (
				<Flex align='center'>
					<Text textAlign="center" color={textColor} fontSize='xs' fontWeight='700' marginLeft='auto' marginRight='auto'>
						{info.getValue()}
					</Text>
				</Flex>
			)
		}),
		columnHelper.accessor('phone', {
			id: 'phone',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					marginLeft='auto'
					marginRight='auto'
					fontSize={{ sm: '10px', lg: '14px' }}
					textTransform="none"
					color='gray.400'
					textAlign="center"
				>
					Telefono
				</Text>
			),
			cell: (info: any) => (
				<Flex align='center'>
					<Text textAlign="center" color={textColor} fontSize='xs' fontWeight='700' marginLeft='auto' marginRight='auto'>
						{info.getValue()}
					</Text>
				</Flex>
			)
		}),
		{
			id: 'address',
			header: () => (
				<Text
					justifyContent='space-between'
					align='center'
					marginLeft='auto'
					marginRight='auto'
					fontSize={{ sm: '10px', lg: '14px' }}
					textTransform="none"
					color='gray.400'
					textAlign="center"
				>
					Indirizzo
				</Text>
			),
			cell: (info: any) => (
				<Flex align='center'>
					<Tooltip label={`${info.row.original.address} - ${info.row.original.addressCity} (${info.row.original.addressState} - ${info.row.original.addressZip})`} placement="top" hasArrow>
						<Text
							textAlign="center"
							color={textColor}
							fontWeight='700'
							marginLeft='auto'
							marginRight='auto'
							noOfLines={2} // Truncate text with ellipsis
							maxWidth={{ base: '200px', sm: '300px', lg: '400px' }} // Increase max width for different screens
							overflow="hidden" // Hide overflow text
							textOverflow="ellipsis" // Add ellipsis for overflow
						>
							{`
								${info.row.original.address || ''}
								${info.row.original.addressCity ? `- ${info.row.original.addressCity}` : ''}
								${info.row.original.addressState ? `${info.row.original.addressState} -` : ''} ${info.row.original.addressZip || ''}
							`.trim()}
						</Text>
					</Tooltip>
				</Flex>
			),
		},
		{
			id: 'operation',
			header: () => (
				<Text
					marginLeft='auto'
					marginRight='auto'
					justifyContent='center'
					align='center'
					fontSize={{ sm: '10px', lg: '14px' }}
					textTransform="none"
					color='gray.400'
				>
					Operazioni
				</Text>
			),
			cell: (info: any) => (
				<Flex justifyContent='center'>
					<Tooltip label="Modifica" fontSize="md">
						<Button
							onClick={() => openEditModal(info.row.original)}
							_hover={{ bg: 'gray.200' }}
							_active={{ bg: 'gray.100' }}
							_focus={{ boxShadow: 'none' }}
							color="white"
							style={{
								height: '30px',
								padding: '0px 12px',
								borderRadius: '6px',
								border: 'none',
								cursor: 'pointer'
							}}
							fontSize='xs'
							mx="1"
						>
							<Icon as={MdOutlineEdit} w={4} h={4} color="black" />
						</Button>
					</Tooltip>
					<Tooltip label="Elimina" fontSize="md">
						<Button
							onClick={() => openDeleteModal(info.row.original.id)}
							_hover={{ bg: 'gray.200' }}
							_active={{ bg: 'gray.100' }}
							_focus={{ boxShadow: 'none' }}
							color="white"
							style={{
								height: '30px',
								padding: '0px 12px',
								borderRadius: '6px',
								border: 'none',
								cursor: 'pointer'
							}}
							fontSize='xs'
							mx="1"
							isLoading={loading}
						>
							<Icon as={MdOutlineDeleteOutline} w={4} h={4} color="black" />
						</Button>
					</Tooltip>
				</Flex>
			)
		}
	];

	const table = useReactTable({
		columns,
		data: pagedCustomers?.content,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		debugTable: false, // Disabilita i log di debug della tabella
		debugHeaders: false, // Disabilita i log di debug degli header
		debugColumns: false  // Disabilita i log di debug delle colonne
	});

	const resetCustomerData = () => {
		setFullName('');
		setVatNumber('');
		setTaxCode('');
		setEmail('');
		setPhone('');
		setAddress('');
		setAddressState('');
		setAddressCity('');
		setAddressZip('');
	};

	const openAddModal = () => {
		setIsAddModalOpen(true);
	};

	const closeAddModal = () => {
		setFullName('');
		setVatNumber('');
		setTaxCode('');
		setEmail('');
		setPhone('');
		setAddress('');
		setAddressState('');
		setAddressCity('');
		setAddressZip('');
		setIsAddModalOpen(false);
	};

	const addCustomer = async () => {
		try {
			setLoading(true);
			const uri = `${process.env.REACT_APP_BACKEND_API_PREFIX}/api/v1/customers`;

			const requestBody = {
				fullName,
				vatNumber,
				taxCode,
				email,
				phone,
				address,
				addressCity,
				addressZip,
				addressState
			};

			resetCustomerData();

			const jsonData = await fetchWithHandling(
				uri,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${jwtToken}`,
					},
					body: JSON.stringify(requestBody)
				},
				() => {
					toast.error(
						"Sessione scaduta. Effettua nuovamente l'accesso.", {
						position: "bottom-right",
						onClose: () => {
							keycloak.logout();
							localStorage.removeItem("kc-token");
						}
					}
					);
				},
			);

			if (jsonData) {
				toast.success(
					"Cliente aggiunto con successo", {
					position: "bottom-right",
				}
				);
				fetchCustomers();
				closeAddModal();
			} else {
				toast.error(
					"Errore durante l'aggiunta del cliente", {
					position: "bottom-right",
				});
			}

			setLoading(false);
		} catch (error) {
			setBackendError(true);
			setLoading(false);
		}
	};

	const deleteCustomer = async (id: number) => {
		try {
			setLoading(true);

			const uri = `${process.env.REACT_APP_BACKEND_API_PREFIX}/api/v1/customers/${id}`;
			const response = await fetchWithHandling(
				uri,
				{
					method: 'DELETE',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${jwtToken}`,
					},
				},
				() => {
					toast.error(
						"Sessione scaduta. Effettua nuovamente l'accesso.", {
						position: "bottom-right",
						onClose: () => {
							keycloak.logout();
							localStorage.removeItem("kc-token");
						}
					}
					);
				}
			);

			if (response.ok) {
				toast.success(
					"Cliente eliminato con successo", {
					position: "bottom-right",
				}
				);
			} else {
				console.error("Errore dal server:", response);
				toast.error(
					"Errore durante l'eliminazione del cliente", {
					position: "bottom-right",
				});
			}

			fetchCustomers();

		} catch (error) {
			setBackendError(true);
		} finally {
			setLoading(false);
		}
	};

	const editCustomer = async () => {
		try {
			setLoading(true);

			const requestBody = {
				fullName,
				vatNumber,
				taxCode,
				email,
				phone,
				address,
				addressCity,
				addressZip,
				addressState
			};

			const uri = `${process.env.REACT_APP_BACKEND_API_PREFIX}/api/v1/customers/${editId}`;
			const jsonData = await fetchWithHandling(
				uri,
				{
					method: 'PUT',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${jwtToken}`,
					},
					body: JSON.stringify(requestBody)
				},
				() => {
					toast.error(
						"Sessione scaduta. Effettua nuovamente l'accesso.", {
						position: "bottom-right",
						onClose: () => {
							keycloak.logout();
							localStorage.removeItem("kc-token");
						}
					}
					);
				}
			);

			if (jsonData) {
				toast.success(
					"Cliente modificato con successo", {
					position: "bottom-right",
				}
				);
				fetchCustomers();
				closeEditModal();
			} else {
				toast.error(
					"Errore durante la modifica del cliente", {
					position: "bottom-right",
				});
			}
			setLoading(false);
		} catch (error) {
			setBackendError(true);
			setLoading(false);
		}
	};

	const openEditModal = (customer: Customer) => {
		setEditId(customer.id);
		setFullName(customer.fullName);
		setVatNumber(customer.vatNumber);
		setTaxCode(customer.taxCode);
		setEmail(customer.email);
		setPhone(customer.phone);
		setAddress(customer.address);
		setAddressState(customer.addressState);
		setAddressCity(customer.addressCity);
		setAddressZip(customer.addressZip);
	};

	const closeEditModal = () => {
		setEditId(null);
		setFullName(null);
		setVatNumber(null);
		setTaxCode(null);
		setEmail(null);
		setPhone(null);
		setAddress(null);
		setAddressState(null);
		setAddressCity(null);
		setAddressZip(null);
	};

	const openDeleteModal = (id: number) => {
		setSelectedId(id);
		setIsDeleteModalOpen(true);
	};

	const closeDeleteModal = () => {
		setIsDeleteModalOpen(false);
		setSelectedId(null);
	};

	const confirmDelete = () => {
		deleteCustomer(selectedId);
		closeDeleteModal();
	};

	const handleVATChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setVatNumber(value);
		// Verifica se il numero di Partita IVA è valido
		setIsVATError(!validateItalianVAT(value));
	};

	const handleTaxCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setTaxCode(value);
		// Verifica se il numero di Partita IVA è valido
		setIsTaxCodeError(!validateItalianTaxCode(value));
	};

	const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setEmail(value);
		// Verifica se il numero di Partita IVA è valido
		setIsEmailError(!validateEmail(value));
	};

	return (
		<Card background='none' p="0px">
			<ToastContainer />
			<SimpleGrid
				px="10px"
				mx="10px"
				width={{ base: '100%', md: '96%' }}
				marginLeft={{ base: '0px', md: '0px' }}
				py='10px'
				backgroundColor='white'
				borderRadius='20px'
				boxShadow={shadow}
				columns={{ base: 2, md: 7, lg: 7, '2xl': 7 }} // Adjust number of columns based on screen size
				mb='20px'
				alignItems="center" // Center items vertically
				justifyContent="center" // Center items horizontally	
			>
				<Card py='15px' gridColumn={{ base: 'span 2', md: 'span 1' }}>
					<Flex h='100%' align={{ base: 'center', xl: 'start' }} justify={{ base: 'center', xl: 'center' }}>
						<Stat my='auto'>
							<StatLabel lineHeight='100%' fontWeight="bold" fontSize={{ base: 'sm' }}>
								Ragione Sociale
							</StatLabel>
							<Flex ms='auto' mt='3%'>
								<Input
									type="text"
									borderRadius='6px'
									size="sm"
									placeholder="Ragione sociale..."
									value={typedFullName}
									onChange={(e) => setTypedFullName(e.target.value)}
									focusBorderColor="gray.500"
									_hover={{ borderColor: 'gray.500' }}
								/>
							</Flex>
						</Stat>
					</Flex>
				</Card>
				<Card py='15px' gridColumn={{ base: 'span 1', md: 'span 1' }}>
					<Flex h='100%' align={{ base: 'center', xl: 'start' }} justify={{ base: 'center', xl: 'center' }}>
						<Stat my='auto'>
							<StatLabel lineHeight='100%' fontWeight="bold" fontSize={{ base: 'sm' }}>
								P. IVA
							</StatLabel>
							<Flex ms='auto' mt='3%'>
								<Input
									type="text"
									borderRadius='6px'
									size="sm"
									placeholder="P. IVA..."
									value={typedVatNumber}
									onChange={(e) => setTypedVatNumber(e.target.value)}
									focusBorderColor="gray.500"
									_hover={{ borderColor: 'gray.500' }}
								/>
							</Flex>
						</Stat>
					</Flex>
				</Card>
				<Card py='15px' gridColumn={{ base: 'span 1', md: 'span 1' }}>
					<Flex h='100%' align={{ base: 'center', xl: 'start' }} justify={{ base: 'center', xl: 'center' }}>
						<Stat my='auto'>
							<StatLabel lineHeight='100%' fontWeight="bold" fontSize={{ base: 'sm' }}>
								Codice Fiscale
							</StatLabel>
							<Flex ms='auto' mt='3%'>
								<Input
									type="text"
									borderRadius='6px'
									size="sm"
									placeholder="Codice fiscale..."
									value={typedTaxCode}
									onChange={(e) => setTypedTaxCode(e.target.value)}
									focusBorderColor="gray.500"
									_hover={{ borderColor: 'gray.500' }}
								/>
							</Flex>
						</Stat>
					</Flex>
				</Card>
				<Card py='15px' gridColumn={{ base: 'span 1', md: 'span 1' }}>
					<Flex h='100%' align={{ base: 'center', xl: 'start' }} justify={{ base: 'center', xl: 'center' }}>
						<Stat my='auto'>
							<StatLabel lineHeight='100%' fontWeight="bold" fontSize={{ base: 'sm' }}>
								Email
							</StatLabel>
							<Flex ms='auto' mt='3%'>
								<Input
									type="text"
									borderRadius='6px'
									size="sm"
									placeholder="Email..."
									value={typedEmail}
									onChange={(e) => setTypedEmail(e.target.value)}
									focusBorderColor="gray.500"
									_hover={{ borderColor: 'gray.500' }}
								/>
							</Flex>
						</Stat>
					</Flex>
				</Card>
				<Card py='15px' gridColumn={{ base: 'span 1', md: 'span 1' }}>
					<Flex h='100%' align={{ base: 'center', xl: 'start' }} justify={{ base: 'center', xl: 'center' }}>
						<Stat my='auto'>
							<StatLabel lineHeight='100%' fontWeight="bold" fontSize={{ base: 'sm' }}>
								Indirizzo
							</StatLabel>
							<Flex ms='auto' mt='3%'>
								<Input
									type="text"
									borderRadius='6px'
									size="sm"
									placeholder="Indirizzo..."
									value={typedAddress}
									onChange={(e) => setTypedAddress(e.target.value)}
									focusBorderColor="gray.500"
									_hover={{ borderColor: 'gray.500' }}
								/>
							</Flex>
						</Stat>
					</Flex>
				</Card>
				<Card py='15px' gridColumn={{ base: 'span 1', md: 'span 1' }}>
					<Flex my='auto' h='100%' align={{ base: 'center', xl: 'start' }} justify={{ base: 'center', xl: 'center' }}>
						<Button
							onClick={() => handleApplyFilters()}
							_hover={{ bg: 'gray.700' }}
							_active={{ bg: 'gray.600' }}
							_focus={{ boxShadow: 'none' }}
							backgroundColor='#688CC8'
							color="white"
							style={{
								height: '30px',
								borderRadius: '6px',
								border: 'none',
								cursor: 'pointer'
							}}
							fontSize='sm'
						>
							Applica Filtri
						</Button>
					</Flex>
				</Card>
				<Card gridColumn={{ base: 'span 1', md: 'span 1' }}>
					<Flex my='auto' h='100%' align={{ base: 'center', xl: 'start' }} justify={{ base: 'center', xl: 'center' }}>
						<Stat my='auto' position="relative">
							<Button
								onClick={() => resetFilters()}
								_hover={{ bg: 'gray.100' }}
								_active={{ bg: 'gray.200' }}
								_focus={{ boxShadow: 'none' }}
								backgroundColor='white'
								color='#688CC8'
								border='2px solid #688CC8'
								style={{
									height: '30px',
									borderRadius: '6px',
									border: 'none',
									cursor: 'pointer'
								}}
								fontSize='sm'
							>
								Azzera Filtri
							</Button>
						</Stat>
					</Flex>
				</Card>
			</SimpleGrid>

			<Card boxShadow={shadow} flexDirection='column' width={{ base: '100%', md: '96%' }} pt='25px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
				<Flex
					px='25px'
					mb="8px"
					justifyContent='space-between'
					align='center'
				>
					<Text color="#9FB50C" fontSize='22px' fontWeight='700' lineHeight='100%'>
						Lista Clienti
					</Text>
					<Flex ml={{ base: '20px', sm: '0auto' }} >
						<Button
							onClick={openAddModal}
							_hover={{ bg: 'gray.700' }}
							_active={{ bg: 'gray.600' }}
							_focus={{ boxShadow: 'none' }}
							backgroundColor='#688CC8'
							color="white"
							style={{
								height: '30px',
								padding: '0px 12px',
								borderRadius: '6px',
								border: 'none',
								cursor: 'pointer',
							}}
							fontSize='xs'
							mx="1"
						>
							+ Aggiungi
						</Button>
						<Button
							onClick={() => fetchCustomers()}
							variant="ghost"
							colorScheme="blue"
							mx="1"
							p={2}
							_hover={{ bg: 'gray.200' }}
						>
							<Icon as={FaSync} w={5} h={5} />
						</Button>
					</Flex>
				</Flex>
				<Box>
					<Table variant='simple' color='gray.500' mb='24px' mt="12px" w='100%' size="sm">
						<Thead>
							{table.getHeaderGroups().map((headerGroup) => (
								<Tr key={headerGroup.id}>
									{headerGroup.headers.map((header) => {
										return (
											<Th
												key={header.id}
												colSpan={header.colSpan}
												pe='10px'
												borderColor={borderColor}
												paddingInline="0px"
											>
												<Flex
													justifyContent='space-between'
													align='center'
													fontSize={{ sm: '10px', lg: '12px' }}
													color='gray.400'>
													{flexRender(header.column.columnDef.header, header.getContext())}{{
														asc: '',
														desc: '',
													}[header.column.getIsSorted() as string] ?? null}
												</Flex>
											</Th>
										);
									})}
								</Tr>
							))}
						</Thead>
						<Tbody>
							{loading ? (
								Array.from({ length: 15 }).map((_, rowIndex) => (
									<Tr key={`loading-row-${rowIndex}`}>
										{columns.map((column, columnIndex) => (
											<Td
												key={`loading-cell-${rowIndex}-${columnIndex}`}
												pb='6px'
												fontSize={{ sm: '14px' }}
												minW={{ sm: '150px', md: '200px', lg: 'auto' }}
												borderColor='transparent'
												textAlign="center"
											>
												{rowIndex === 0 ? <Spinner size="sm" color="gray.500" /> : null}
											</Td>
										))}
									</Tr>
								))
							) : pagedCustomers?.content?.length > 0 ? (
								<>
									{table.getRowModel().rows.map((row) => (
										<Tr
											key={row.id}
											_hover={{ bg: 'gray.100' }}
											_active={{ bg: 'gray.200' }}
											_focus={{ boxShadow: 'none' }}
										>
											{row.getVisibleCells().map((cell) => (
												<Td
													pb='2px'
													key={cell.id}
													fontSize={{ sm: '14px' }}
													minW={{ sm: '150px', md: '200px', lg: 'auto' }}
													borderColor='transparent'
												>
													{flexRender(cell.column.columnDef.cell, cell.getContext())}
												</Td>
											))}
										</Tr>
									))}
								</>
							) : (
								<Tr>
									<Td colSpan={columns.length} textAlign="center">
										Nessun cliente
									</Td>
								</Tr>
							)}
						</Tbody>
						<Tfoot w="100%">
							<Tr>
								<Td colSpan={8} textAlign="center">
									<Flex justifyContent="center" alignItems="center" mt={4}>
										<Button
											onClick={() => handlePageChange(currentPage - 1)}
											isDisabled={pagedCustomers?.pageable?.pageNumber === 0}
											height="100%"
										>
											Precedente
										</Button>
										<Text mx={2}>
											Pagina {pagedCustomers ? pagedCustomers.pageable?.pageNumber + 1 : 0} di {pagedCustomers ? pagedCustomers.totalPages : 0}
										</Text>
										<Button
											onClick={() => handlePageChange(currentPage + 1)}
											isDisabled={pagedCustomers?.pageable?.pageNumber === pagedCustomers?.totalPages - 1}
											height="100%"
										>
											Successiva
										</Button>
										<Flex alignItems="center" mx={2}>
											<Input
												type="number"
												placeholder="Pagina"
												value={inputPageNumber}
												onChange={(e) => setInputPageNumber(e.target.value)}
												width="90px"
												mx={2}
											/>
											<Button
												onClick={() => {
													const pageNumber = parseInt(inputPageNumber, 10);
													if (!isNaN(pageNumber) && pageNumber > 0 && pageNumber <= pagedCustomers?.totalPages) {
														handlePageChange(pageNumber);
													}
												}}
											>
												Vai
											</Button>
										</Flex>
									</Flex>
								</Td>
							</Tr>
						</Tfoot>
					</Table>
				</Box>

				{/* Add Modal */}
				<Modal isOpen={isAddModalOpen} onClose={closeAddModal}>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader color="#9FB50C">Nuovo Cliente</ModalHeader>
						<ModalCloseButton _focus={{ boxShadow: 'none' }} />
						<ModalBody>
							<Text fontWeight="bold">
								Ragione Sociale <Text as="span" color="red">*</Text>
							</Text>
							<Input
								_focus={{ boxShadow: 'black' }}
								placeholder="Ragione Sociale"
								width="100%"
								value={fullName}
								onChange={(e) => setFullName(e.target.value)}
							/>
							<FormControl isInvalid={isVATError}>
								<Text fontWeight="bold" mt="4">P. IVA</Text>
								<Input
									_focus={{ boxShadow: 'black' }}
									placeholder="P. IVA"
									width="100%"
									value={vatNumber}
									onChange={handleVATChange}
									maxLength={11} // Limita l'input a 11 caratteri
									inputMode="numeric" // Mostra la tastiera numerica su dispositivi mobili
									pattern="\d*" // Accetta solo numeri
								/>
								{isVATError && (
									<FormErrorMessage>Partita IVA non valida. Deve essere composta da 11 cifre.</FormErrorMessage>
								)}
							</FormControl>
							<FormControl isInvalid={isTaxCodeError}>
								<Text fontWeight="bold" mt="4">Codice Fiscale</Text>
								<Input
									_focus={{ boxShadow: 'black' }}
									placeholder="Codice Fiscale"
									width="100%"
									value={taxCode}
									onChange={handleTaxCodeChange}
									maxLength={16} // Limita l'input a 16 caratteri
								/>
								{isTaxCodeError && (
									<FormErrorMessage>Codice Fiscale non valido..</FormErrorMessage>
								)}
							</FormControl>
							<FormControl isInvalid={isEmailError}>
								<Text fontWeight="bold" mt="4">Email</Text>
								<Input
									_focus={{ boxShadow: 'black' }}
									placeholder="Email"
									width="100%"
									value={email}
									onChange={handleEmailChange}
								/>
								{isEmailError && (
									<FormErrorMessage>Formato email non corretto.</FormErrorMessage>
								)}
							</FormControl>
							<Text fontWeight="bold" mt="4">Telefono</Text>
							<Input
								_focus={{ boxShadow: 'black' }}
								placeholder="Telefono"
								width="100%"
								value={phone}
								onChange={(e) => setPhone(e.target.value)}
							/>
							<Text fontWeight="bold" mt="4">Indirizzo</Text>
							<Input
								_focus={{ boxShadow: 'black' }}
								placeholder="Indirizzo"
								width="100%"

								value={address}
								onChange={(e) => setAddress(e.target.value)}
							/>
							<Flex width="100%" mt="4" gap="4">
								<Input
									_focus={{ boxShadow: 'black' }}
									placeholder="Città"
									flex="1"
									value={addressCity}
									onChange={(e) => setAddressCity(e.target.value)}
								/>
								<Input
									_focus={{ boxShadow: 'black' }}
									placeholder="Provincia"
									flex="1"
									value={addressState}
									onChange={(e) => setAddressState(e.target.value)}
									maxLength={2}
								/>
								<Input
									_focus={{ boxShadow: 'black' }}
									placeholder="CAP"
									flex="1"
									value={addressZip}
									onChange={(e) => setAddressZip(e.target.value)}
								/>
							</Flex>
						</ModalBody>
						<ModalFooter>
							<Tooltip
								label="Inserisci una ragione sociale per salvare"
								isDisabled={fullName ? true : false}
								shouldWrapChildren
							>
								<Button
									backgroundColor={'#688CC8'}
									color="white"
									mr={3}
									onClick={addCustomer}
									isLoading={loading}
									disabled={fullName ? false : true}
								>
									Salva
								</Button>
							</Tooltip>
							<Button colorScheme="gray" onClick={closeAddModal}>Annulla</Button>
						</ModalFooter>
					</ModalContent>
				</Modal>
				{/* Edit Modal */}
				<Modal isOpen={!!editId} onClose={closeEditModal}>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader color="#9FB50C">Modifica Cliente</ModalHeader>
						<ModalCloseButton _focus={{ boxShadow: 'none' }} />
						<ModalBody>
							<Text fontWeight="bold">
								Ragione Sociale <Text as="span" color="red">*</Text>
							</Text>
							<Input
								_focus={{ boxShadow: 'black' }}
								placeholder="Ragione Sociale"
								width="100%"
								value={fullName}
								onChange={(e) => setFullName(e.target.value)}
							/>
							<FormControl isInvalid={isVATError}>
								<Text fontWeight="bold" mt="4">P. IVA</Text>
								<Input
									_focus={{ boxShadow: 'black' }}
									placeholder="P. IVA"
									width="100%"
									value={vatNumber}
									onChange={handleVATChange}
									maxLength={11} // Limita l'input a 11 caratteri
									inputMode="numeric" // Mostra la tastiera numerica su dispositivi mobili
									pattern="\d*" // Accetta solo numeri
								/>
								{isVATError && (
									<FormErrorMessage>Partita IVA non valida. Deve essere composta da 11 cifre.</FormErrorMessage>
								)}
							</FormControl>
							<FormControl isInvalid={isTaxCodeError}>
								<Text fontWeight="bold" mt="4">Codice Fiscale</Text>
								<Input
									_focus={{ boxShadow: 'black' }}
									placeholder="Codice Fiscale"
									width="100%"
									value={taxCode}
									onChange={handleTaxCodeChange}
									maxLength={16} // Limita l'input a 16 caratteri
								/>
								{isTaxCodeError && (
									<FormErrorMessage>Codice Fiscale non valido..</FormErrorMessage>
								)}
							</FormControl>
							<FormControl isInvalid={isEmailError}>
								<Text fontWeight="bold" mt="4">Email</Text>
								<Input
									_focus={{ boxShadow: 'black' }}
									placeholder="Email"
									width="100%"
									value={email}
									onChange={handleEmailChange}
								/>
								{isEmailError && (
									<FormErrorMessage>Formato email non corretto.</FormErrorMessage>
								)}
							</FormControl>
							<Text fontWeight="bold" mt="4">Telefono</Text>
							<Input
								_focus={{ boxShadow: 'black' }}
								placeholder="Telefono"
								width="100%"
								value={phone}
								onChange={(e) => setPhone(e.target.value)}
							/>
							<Text fontWeight="bold" mt="4">Indirizzo</Text>
							<Input
								_focus={{ boxShadow: 'black' }}
								placeholder="Indirizzo"
								width="100%"

								value={address}
								onChange={(e) => setAddress(e.target.value)}
							/>
							<Flex width="100%" mt="4" gap="4">
								<Input
									_focus={{ boxShadow: 'black' }}
									placeholder="Città"
									flex="1"
									value={addressCity}
									onChange={(e) => setAddressCity(e.target.value)}
								/>
								<Input
									_focus={{ boxShadow: 'black' }}
									placeholder="Provincia"
									flex="1"
									value={addressState}
									onChange={(e) => setAddressState(e.target.value)}
									maxLength={2}
								/>
								<Input
									_focus={{ boxShadow: 'black' }}
									placeholder="CAP"
									flex="1"
									value={addressZip}
									onChange={(e) => setAddressZip(e.target.value)}
								/>
							</Flex>
						</ModalBody>
						<ModalFooter>
							<Tooltip
								label="Inserisci una ragione sociale per salvare"
								isDisabled={fullName ? true : false}
								shouldWrapChildren
							>
								<Button
									backgroundColor={'#688CC8'}
									color="white"
									mr={3}
									onClick={editCustomer}
									isLoading={loading}
									disabled={fullName ? false : true}
								>
									Salva
								</Button>
							</Tooltip>
							<Button colorScheme="gray" onClick={closeEditModal}>Annulla</Button>
						</ModalFooter>
					</ModalContent>
				</Modal>
				{/* Conferma eliminazione */}
				<Modal isOpen={isDeleteModalOpen} onClose={closeDeleteModal}>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader color="#9FB50C">Conferma Eliminazione</ModalHeader>
						<ModalCloseButton _focus={{ boxShadow: 'none' }} />
						<ModalBody>
							Sei sicuro di voler eliminare questo cliente?
						</ModalBody>
						<ModalFooter>
							<Button colorScheme="red" mr={3} onClick={confirmDelete}>
								Elimina
							</Button>
							<Button colorScheme="gray" onClick={closeDeleteModal}>
								Annulla
							</Button>
						</ModalFooter>
					</ModalContent>
				</Modal>
			</Card>
		</Card>
	);
}