import { Box, Flex, Stack } from '@chakra-ui/react';
import Brand from 'components/sidebar/components/Brand';
import Links from 'components/sidebar/components/Links';
import SidebarCard from 'components/sidebar/components/SidebarCard';

function SidebarContent(props: { routes: RoutesType[], onClose?: () => void }) {
	const { routes, onClose } = props;

	// SIDEBAR
	return (
		<Flex direction='column' height='100%' pt='25px' borderRadius='30px'>
			<Brand />
			<Stack direction='column' mt='8px' mb='auto'>
				<Box ps='9px' pt='50px' pe={{ lg: '16px', '2xl': '16px' }}>
					<Links routes={routes} onClose={onClose}/>
				</Box>
			</Stack>
			<Box ps='20px' pe={{ lg: '16px', '2xl': '20px' }} mt='60px' mb='40px' borderRadius='30px'>
				<SidebarCard onClose={onClose} />
			</Box>
		</Flex>
	);
}

export default SidebarContent;
