import {
  Box,
  Button,
  Icon,
  SimpleGrid,
  Spinner,
  useColorModeValue,
} from "@chakra-ui/react";
import { useKeycloak } from "@react-keycloak/web";
import { add, endOfMonth, format, startOfMonth } from "date-fns";
import { it } from "date-fns/locale";
import { useEffect, useState } from "react";
import { MdQueryStats } from "react-icons/md";
import MiniStatistics from "../../components/card/MiniStatistics";
import IconBox from "../../components/icons/IconBox";
import { TotalDeadlinesStats } from "../../utils/interfaces";
import DeadlinesTable from "./components/DeadlinesTable";

export default function Deadlines() {
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );

  const initialResult: TotalDeadlinesStats = {
    todayDeadlines: 0,
    thisMonthDeadlines: 0,
    thisWeekDeadlines: 0,
  };

  const [result, setResult] = useState<TotalDeadlinesStats>(initialResult);
  const [loading, setLoading] = useState(true);
  const [selectedRange, setSelectedRange] = useState(""); // Stato per il range selezionato

  const today = new Date();
  const oneWeekAhead = add(today, { weeks: 1 });

  const formatoData = "dd MMMM";
  const todayFormat = format(today, formatoData, { locale: it });
  const weeklyRange = `${todayFormat} - ${format(oneWeekAhead, formatoData, {
    locale: it,
  })}`;

  const firstDayOfMonth = startOfMonth(today);
  const lastDayOfMonth = endOfMonth(today);
  const firstDayFormatted = format(firstDayOfMonth, formatoData, {
    locale: it,
  });
  const lastDayFormatted = format(lastDayOfMonth, formatoData, { locale: it });

  const monthRange = `${firstDayFormatted} - ${lastDayFormatted}`;

  const { keycloak } = useKeycloak();

  useEffect(() => {
    const fetchData = async () => {
      let data;
      try {
        data = await fetch(
          `${process.env.REACT_APP_BACKEND_API_PREFIX}/api/v1/deadlines/statistics`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${keycloak.token}`,
            },
          }
        );
        const jsonData = await data.json();
        setResult(jsonData);
      } catch (error) {
        console.error("Errore durante il fetch delle statistiche.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resetSelectedRange = () => {
    setSelectedRange("");
  };

  return (
    <Box pt={{ base: "40px", md: "80px", xl: "78px" }}>
      <Box>
        <SimpleGrid
          width={{ base: "100%", md: "96%" }}
          pb="10px"
          backgroundColor="white"
          borderRadius="20px"
          boxShadow={shadow}
          columns={{ base: 1, md: 2, lg: 3, "2xl": 3 }}
          gap="0px"
          mb="20px"
        >
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg={boxBg}
                icon={
                  <Icon w="32px" h="32px" as={MdQueryStats} color={"#688CC8"} />
                }
              />
            }
            name={`Scadenze oggi (${todayFormat}):`}
            value={loading ? <Spinner size="sm" /> : result.todayDeadlines}
            endContent={
              <Button
                padding="0px 8px"
                borderRadius="5px"
                marginLeft="0px"
                fontSize="xs"
                bg="white"
                height="20px"
                color="#688CC8"
                border="1px solid #688CC8"
                onClick={() => setSelectedRange("today")}
              >
                Guarda Ora &gt;
              </Button>
            }
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg={boxBg}
                icon={
                  <Icon w="32px" h="32px" as={MdQueryStats} color={"#688CC8"} />
                }
              />
            }
            name={`Settimana corrente (${weeklyRange}):`}
            value={loading ? <Spinner size="sm" /> : result.thisWeekDeadlines}
            endContent={
              <Button
                padding="0px 8px"
                borderRadius="5px"
                marginLeft="0px"
                fontSize="xs"
                bg="white"
                height="20px"
                color="#688CC8"
                border="1px solid #688CC8"
                onClick={() => setSelectedRange("week")}
              >
                Guarda Ora &gt;
              </Button>
            }
          />

          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg={boxBg}
                icon={
                  <Icon w="32px" h="32px" as={MdQueryStats} color={"#688CC8"} />
                }
              />
            }
            name={`Mese corrente (${monthRange}):`}
            value={loading ? <Spinner size="sm" /> : result.thisMonthDeadlines}
            endContent={
              <Button
                padding="0px 8px"
                borderRadius="5px"
                marginLeft="0px"
                fontSize="xs"
                bg="white"
                height="20px"
                color="#688CC8"
                border="1px solid #688CC8"
                onClick={() => setSelectedRange("month")}
              >
                Guarda Ora &gt;
              </Button>
            }
          />
        </SimpleGrid>
      </Box>
      <Box mt="40px">
        <DeadlinesTable
          selectedRange={selectedRange}
          keycloak={keycloak}
          resetSelectedRange={resetSelectedRange}
        />
      </Box>
    </Box>
  );
}
