import {
    Box
} from '@chakra-ui/react';
import { useKeycloak } from '@react-keycloak/web';
import NotificationDeadlinesReminderTable from './components/NotificationDeadlinesReminderTable';

export default function Home() {

    const { keycloak } = useKeycloak();

    return (
        <Box pt={{ base: "40px", md: "80px", xl: "78px" }}>
            <NotificationDeadlinesReminderTable keycloak={keycloak}/>
        </Box>
    );
}