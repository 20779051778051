import {
	Box, Button,
	Flex,
	Icon, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useColorModeValue
} from '@chakra-ui/react';
import {
	createColumnHelper,
	flexRender,
	getCoreRowModel, getFilteredRowModel,
	getSortedRowModel,
	useReactTable
} from '@tanstack/react-table';
import Card from 'components/card/Card';
import { useEffect, useState } from "react";
import { MdOutlineDeleteOutline, MdOutlineEdit } from 'react-icons/md';
import { toast, ToastContainer } from "react-toastify";
import { DeadlinesCategoryType } from "../../../utils/interfaces";
import 'react-toastify/dist/ReactToastify.css';
import { useKeycloak } from '@react-keycloak/web';
import { fetchWithHandling } from 'utils/common-utils';

const columnHelper = createColumnHelper<DeadlinesCategoryType>();

export default function DeadlinesCategoryConfigurations() {

	const jwtToken = localStorage.getItem('kc-token');
	const { keycloak } = useKeycloak();

	const [allData, setSourceDetails] = useState<DeadlinesCategoryType[]>([]);
	const [loading, setLoading] = useState(false);
	const [newName, setNewName] = useState("");
	const [editId, setEditId] = useState(null);
	const [isAddModalOpen, setIsAddModalOpen] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage] = useState(10);

	// Error
	const [backendError, setBackendError] = useState(false);

	const startIndex = (currentPage - 1) * itemsPerPage;
	const endIndex = startIndex + itemsPerPage;
	const data = allData.slice(startIndex, endIndex);
	const totalPages = (allData.length === 0) ? 1 : Math.ceil(allData.length / itemsPerPage);

	useEffect(() => {
		fetchSourceData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (backendError) {
			toast.error(
				"Errore durante la chiamata al Server, perfavore riprova.", {
				position: "bottom-right",
			});
		}
	}, [backendError]);

	const nextPage = () => {
		setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
	};

	const prevPage = () => {
		setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
	};

	const closeAddModal = () => {
		setNewName("");
		setIsAddModalOpen(false);
	};

	const textColor = useColorModeValue('secondaryGray.900', 'white');

	const columns = [
		columnHelper.accessor('id', {
			id: 'id',
			header: () => (
				<Text
					marginLeft='auto'
					marginRight='auto'
					justifyContent='center'
					align='center'
					fontSize={{ sm: '10px', lg: '14px' }}
					textTransform="none"
					color='gray.400'>
					#
				</Text>
			),
			cell: (info: any) => (
				<Flex align='center'>
					<Text color={textColor} fontSize='xs' fontWeight='700' marginLeft='auto' marginRight='auto'>
						{startIndex + info.row.index + 1}
					</Text>
				</Flex>
			)
		}),
		columnHelper.accessor('name', {
			id: 'name',
			header: () => (
				<Text
					marginLeft='auto'
					marginRight='auto'
					justifyContent='space-between'
					align='center'
					fontSize={{ sm: '10px', lg: '14px' }}
					textTransform="none"
					color='gray.400'>
					Categoria
				</Text>
			),
			cell: (info: any) => (
				<Flex align='center'>
					<Text color={textColor} fontSize='xs' fontWeight='700' marginLeft='auto' marginRight='auto'>
						{info.getValue()}
					</Text>
				</Flex>
			)
		}),
		{
			id: 'operation',
			header: () => (
				<Text
					marginLeft='auto'
					marginRight='auto'
					justifyContent='center'
					align='center'
					fontSize={{ sm: '10px', lg: '14px' }}
					textTransform="none"
					color='gray.400'>
					Azione
				</Text>
			),
			cell: (info: any) => (
				<Flex justifyContent='center'>
					<Button
						onClick={() => openEditModal(info.row.original.id, info.row.original.name)}
						_hover={{ bg: 'gray.200' }}
						_active={{ bg: 'gray.100' }}
						_focus={{ boxShadow: 'none' }}
						color="white"
						style={{
							height: '30px',
							padding: '0px 12px',
							borderRadius: '6px',
							border: 'none',
							cursor: 'pointer'
						}}
						fontSize='xs'
						mx="1"
					>
						<Icon as={MdOutlineEdit} w={4} h={4} color="black" />
					</Button>
					<Button
						onClick={() => deleteCategory(info.row.original.id)}
						_hover={{ bg: 'gray.200' }}
						_active={{ bg: 'gray.100' }}
						_focus={{ boxShadow: 'none' }}
						color="white"
						style={{
							height: '30px',
							padding: '0px 12px',
							borderRadius: '6px',
							border: 'none',
							cursor: 'pointer'
						}}
						fontSize='xs'
						mx="1"
					>
						<Icon as={MdOutlineDeleteOutline} w={4} h={4} color="black" />
					</Button>
				</Flex>
			)
		}
	];

	const table = useReactTable({
		columns,
		data,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		debugTable: false, // Disabilita i log di debug della tabella
		debugHeaders: false, // Disabilita i log di debug degli header
		debugColumns: false  // Disabilita i log di debug delle colonne

	});

	const refreshSourceData = () => {
		fetchSourceData();
	};

	const fetchSourceData = async () => {
		try {
			setLoading(true);
			const uri = `${process.env.REACT_APP_BACKEND_API_PREFIX}/api/v1/deadlines_category`

			const jsonData = await fetchWithHandling(
				uri,
				{
					method: 'GET',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${jwtToken}`,
					},
				},
				() => {
					toast.error(
						"Sessione scaduta. Effettua nuovamente l'accesso.", {
						position: "bottom-right",
						onClose: () => {
							keycloak.logout();
							localStorage.removeItem("kc-token");
						}
					}
					);
				}
			);

			if (jsonData) {
				setSourceDetails(jsonData);
			}
			setLoading(false);
		} catch (error) {
			setBackendError(true);
			setSourceDetails([]); // Ensure allData is set to an empty array on error
			setLoading(false);
		}
	};

	const deleteCategory = async (id: string) => {
		try {
			setLoading(true);
			const uri = `${process.env.REACT_APP_BACKEND_API_PREFIX}/api/v1/deadlines_category/${id}`;
			const response = await fetchWithHandling(
				uri,
				{
					method: 'DELETE',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${jwtToken}`,
					},
				},
				() => {
					toast.error(
						"Sessione scaduta. Effettua nuovamente l'accesso.", {
						position: "bottom-right",
						onClose: () => {
							keycloak.logout();
							localStorage.removeItem("kc-token");
						}
					}
					);
				}
			);

			if (response) {
				toast.success("Categoria eliminata con successo", { position: "bottom-right" });
			} else {
				toast.error("Errore durante l'eliminetazione della categoria", { position: "bottom-right" });
			}

			refreshSourceData();
			setLoading(false);
		} catch (error) {
			setBackendError(true);
			setLoading(false);
		}
	};

	const editCategory = async () => {
		if (editId !== null) {
			try {
				setLoading(true);
				const uri = `${process.env.REACT_APP_BACKEND_API_PREFIX}/api/v1/deadlines_category`;
				const response = await fetchWithHandling(
					uri,
					{
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
							Authorization: `Bearer ${jwtToken}`,
						},
						body: JSON.stringify({
							id: editId,
							name: newName
						})

					},
					() => {
						toast.error(
							"Sessione scaduta. Effettua nuovamente l'accesso.", {
							position: "bottom-right",
							onClose: () => {
								keycloak.logout();
								localStorage.removeItem("kc-token");
							}
						}
						);
					}
				);

				if (response) {
					setNewName("");
					closeEditModal();
					toast.success("Categoria modificata con successo", { position: "bottom-right" });
				} else {
					toast.error("Errore durante la modifica della categoria", { position: "bottom-right" });
				}

				refreshSourceData();
				setLoading(false);
			} catch (error) {
				setBackendError(true);
				setLoading(false);
			}
		}
	};

	const addCategory = async () => {
		try {
			setLoading(true);
			const uri = `${process.env.REACT_APP_BACKEND_API_PREFIX}/api/v1/deadlines_category`;

			const response = await fetchWithHandling(
				uri,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${jwtToken}`,
					},
					body: JSON.stringify({
						name: newName
					})

				},
				() => {
					toast.error(
						"Sessione scaduta. Effettua nuovamente l'accesso.", {
						position: "bottom-right",
						onClose: () => {
							keycloak.logout();
							localStorage.removeItem("kc-token");
						}
					}
					);
				}
			);

			if (response) {
				setNewName("");
				toast.success("Categoria aggiunta con successo", { position: "bottom-right" });
			} else {
				toast.error("Errore durante la creazione della categoria", { position: "bottom-right" });
			}

			refreshSourceData();
			setLoading(false);
		} catch (error) {
			setBackendError(true);
			setLoading(false);
		}

		setIsAddModalOpen(false);

	};

	const openEditModal = (id: string, name: string) => {
		setEditId(id);
		setNewName(name);
	};

	const closeEditModal = () => {
		setEditId(null);
		setNewName("");
	};

	const openAddModal = () => {
		setIsAddModalOpen(true);
	};

	return (
		<Box px='20px' mb="0px">
			{loading ? (
				<Flex justifyContent='center' alignItems='center' height='100vh'>
					<Spinner size='xl' />
				</Flex>
			) : (
				<Card>
					<ToastContainer />
					<Flex alignItems='center' mb={4}>
						<Text fontSize='xl' fontWeight='bold'>Categorie</Text>
						<Button
							onClick={openAddModal}
							_hover={{ bg: 'gray.700' }}
							_active={{ bg: 'gray.600' }}
							_focus={{ boxShadow: 'none' }}
							backgroundColor='#688CC8'
							color="white"
							style={{
								height: '30px',
								padding: '0px 12px',
								borderRadius: '6px',
								border: 'none',
								cursor: 'pointer',
								marginLeft: 'auto'
							}}
							fontSize='xs'
							mx="1"
						>
							+ Aggiungi
						</Button>
					</Flex>
					<Table>
						<Thead>
							{table.getHeaderGroups().map(headerGroup => (
								<Tr key={headerGroup.id} p={1}>
									{headerGroup.headers.map(header => (
										<Th key={header.id} p={1}>
											{flexRender(header.column.columnDef.header, header.getContext())}
										</Th>
									))}
								</Tr>
							))}
						</Thead>
						<Tbody>
							{table.getRowModel().rows.map(row => (
								<Tr key={row.id} p={1}>
									{row.getVisibleCells().map(cell => (
										<Td key={cell.id} p={0.5}>
											{flexRender(cell.column.columnDef.cell, cell.getContext())}
										</Td>
									))}
								</Tr>
							))}
						</Tbody>
					</Table>
					<Flex justifyContent='center' alignItems='center' mt={4}>
						<Button fontSize={{ base: 'xs', md: 'md' }} onClick={prevPage} isDisabled={currentPage === 1}>
							Precedente
						</Button>
						<Text fontSize={{ base: 'xs', md: 'md' }}>
							{`Pagina ${currentPage} di ${totalPages}`}
						</Text>						<Button fontSize={{ base: 'xs', md: 'md' }} onClick={nextPage} isDisabled={currentPage === totalPages}>
							Successiva
						</Button>
					</Flex>
				</Card>
			)}
			{/* Modal */}
			<Modal isOpen={!!editId} onClose={closeEditModal}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader color={"#9FB50C"}>Modifica Categoria</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Input
							placeholder="Inserisci nuova categoria"
							value={newName}
							onChange={(e) => setNewName(e.target.value)}
						/>
					</ModalBody>
					<ModalFooter>
						<Button color="white" background="#688CC8" mr={3} onClick={editCategory}>
							Salva
						</Button>
						<Button variant="ghost" onClick={closeEditModal}>
							Annulla
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<Modal isOpen={isAddModalOpen} onClose={closeAddModal}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader color={"#9FB50C"}>Nuova Categoria</ModalHeader>
					<ModalCloseButton _focus={{ boxShadow: 'none' }} />
					<ModalBody>
						<Input
							_focus={{ boxShadow: 'black' }}
							placeholder="Nuova Categoria"
							value={newName}
							onChange={(e) => setNewName(e.target.value)}
						/>
					</ModalBody>
					<ModalFooter>
						<Button colorScheme="gray" mr={3} onClick={() => addCategory()} color="white" background="#688CC8">
							Salva
						</Button>
						<Button colorScheme="gray" onClick={closeAddModal}>Annulla</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</Box>
	);
}
