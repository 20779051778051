import { Box, Flex, Text } from '@chakra-ui/react';
import DeadlinesCategoryConfigurations from './components/DeadlinesCategoryConfigurations';
import DeadlinesTipologyConfigurations from "./components/DeadlinesTipologyConfigurations";
import EmailNotificationsTemplate from './components/EmailNotificationsTemplate';

export default function Configurations() {

    return (
        <Box pt={{ base: '70px', md: '70px', xl: '70px' }}>
            <Text pb={'30px'} color={"#9FB50C"} fontSize='xl' fontWeight='bold'>Gestione Scadenze</Text>
            <Flex mt='0px' direction={{ base: 'column', md: 'row' }} height='100%'>
                <Box flex='1' height='100%'>
                    <DeadlinesCategoryConfigurations />
                </Box>
                <Box flex='1' height='100%'>
                    <DeadlinesTipologyConfigurations />
                </Box>
            </Flex>
            <Flex mt='30px' direction={{ base: 'column', md: 'row' }} height='100%'>
                <Box flex={{ base: '1', md: '0 0 50%' }} height='100%'>
                    <EmailNotificationsTemplate />
                </Box>
            </Flex>
        </Box>
    );
}

