import { Box, Flex } from '@chakra-ui/react';
import logo from '../../../utils/assets/img/logo.png';

export function SidebarBrand() {
	return (
		<Flex alignItems='center' flexDirection='column'>
			<Box h='36px' w='195px' my='10px' mb='20px' mr='0px' ml='20px'>
			<img src={logo} alt="Brand Logo" height="26px" width="146px" style={{ margin: '30px 0' }} />
			</Box>
		</Flex>
	);
}

export default SidebarBrand;
