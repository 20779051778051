import {
	Box, useColorModeValue
} from '@chakra-ui/react';
import { renderThumb, renderTrack, renderView } from 'components/scrollbar/Scrollbar';
import Content from 'components/sidebar/components/Content';
import { Scrollbars } from 'react-custom-scrollbars-2';

function Sidebar(props: { routes: RoutesType[];[x: string]: any }) {
	const { routes } = props;
	const filteredRoutes = routes.filter(route => route.hidden !== true);

	let variantChange = '0.2s linear';
	let shadow = useColorModeValue('14px 17px 40px 4px rgba(112, 144, 176, 0.08)', 'unset');
	let sidebarBg = useColorModeValue('white', 'navy.800');
	let sidebarMargins = '0px';

	// SIDEBAR
	return (
		<Box display={{ sm: 'none', xl: 'block' }} position='fixed' minH='100%'>
			<Box
				bg={sidebarBg}
				transition={variantChange}
				w='225px'
				h='100vh'
				m={sidebarMargins}
				minH='100%'
				overflowX='hidden'
				boxShadow={shadow}>
				<Scrollbars
					autoHide
					renderTrackVertical={renderTrack}
					renderThumbVertical={renderThumb}
					renderView={renderView}>
					<Content routes={filteredRoutes} />
				</Scrollbars>
			</Box>
		</Box>
	);
}

export default Sidebar;
