import { Icon } from '@chakra-ui/react';
import SignIn from 'pages/auth/signIn';
import Customers from 'pages/customers';
import Deadlines from 'pages/deadlines';
import Home from 'pages/home';
import { FaUsers } from "react-icons/fa";
import { HiBellAlert } from "react-icons/hi2";
import { IoHome } from "react-icons/io5";
import Configurations from "./pages/configurations";

const routes = [
	{
		name: 'Home',
		layout: '/admin',
		path: '/home',
		icon: <Icon as={IoHome} width='20px' height='20px' marginTop='7px' color='inherit' />,
		component: Home
	},
	{
		name: 'Anagrafica Clienti',
		layout: '/admin',
		path: '/anagrafica-clienti',
		icon: <Icon as={FaUsers} width='20px' height='20px' marginTop='7px' color='inherit' />,
		component: Customers
	},
	{
		name: 'Gestione Scadenze',
		layout: '/admin',
		path: '/scadenze',
		icon: <Icon as={HiBellAlert} width='20px' height='20px' marginTop='7px' color='inherit' />,
		component: Deadlines
	},
	{
        name: 'Configurazioni',
        layout: '/admin',
        path: '/configurazioni',
        hidden: true,
        component: Configurations
    },
	{
		name: 'Sign In',
		layout: '/auth',
		path: '/',
		component: SignIn,
		hidden: true
	}
];

export default routes;
